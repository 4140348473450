import React from 'react'
import flag from "./region/Kochenevskiy/herb1.png"
import emblem from "./region/Kochenevskiy/he1.png"
import church_1_image from "./region/Kochenevskiy/h1.jpg"
import ad1 from "./region/Kochenevskiy/ad1.jpg"
import a1 from "./region/Kochenevskiy/a1.jpg"
import a2 from "./region/Kochenevskiy/a2.jpeg"

function Kochenevskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Коченевский район</title>
            </div>
            <section class="region">
                
            <table>
                
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180}/>
                    </div>
                    <h1 className='head'>Коченёвский район</h1>
                </div>
                

           
                <div class="about-region">
                    
                        <div class="inf-4">
                        В 1925 году был образован Сибирский край, а в его составе — Коченёвский район 
                        Ново-Николаевского (Новосибирского) округа. 
                        

                        В 1930 году Сибирский край был разделён на западную и восточную части, 
                        и район оказался в составе Западно-Сибирского края. 
                         <div>В 1937 году район был включён в состав новообразованной Новосибирской области.
                        Население – 44 998 человек (2021 г). Административный центр – рабочий поселок Коченёво.</div>
                        
                    </div>
                </div>
                <div class='center-image'> <img src={ad1} width={700}/> </div>
                </table>

                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Коченёвского района</h2>
                    </div>
                   
                    
                   
                   
                   
                    

                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм в честь архистратига Михаила и прочих Небесных Сил бесплотных, р.п. Коченёво</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={400}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        В 1898 году в селе Коченёвское (ныне Коченёво) была построена и освящена деревянная 
                        однопрестольная церковь во имя Архистратига Михаила.
                        Рядом с храмом была построена церковно-приходская школа. 
                        При приходе была походная церковь, приобретенная в 1909 году.
                        <div>В годы гонений в 1937 году церковь закрыли, устроили в ней клуб. 
                            После окончания Великой Отечественной войны в поруганном храме открыли школу. 
                            В 1949 году здание сгорело.</div>
                        <div>Наступило иное время, время духовного возрождения нашего Отечества после празднования 
                            Тысячелетия Крещения Руси. Стали открывать новые храмы и приходы, восстанавливать разрушенные.
                        В 1992 году Владыка Тихон, епископ Новосибирский и Барнаульский, благословил верующих на создание 
                        в Коченево православного прихода. 22 января 1992 года состоялось собрание с благочинным церквей 
                        г. Новосибирска протоиереем Павлом Патриным.  
                        17 марта 1992 года православный Свято-Михайловский приход был зарегистрирован.</div>
                        <div>Администрация района передала прихожанам здание в Саратовском переулке. 
                            Силами общины помещение было приведено в надлежащий вид. 
                            28 марта 1992 года прошло первое вечернее богослужение. 
                            На следующий день — освящение храма и Божественная Литургия.</div>

                        <div>15 сентября 1995 года епископ Софроний освятил закладной камень в основание будущего храма в 
                            честь Архистратига Михаила. Началось строительство нового храма. 
                             11 апреля 2002 года Высокопреосвященнейший Тихон, архиепископ Новосибирский и Бердский, освятил 
                             купола и кресты, они были установлены на новый храм. 
                            В 2005 году на Пасху прошло первое богослужение.</div>

                            
                            <p><b>Адрес:</b> рабочий поселок Коченёво, пер. Саратовский, д. 3.</p>

                                            
                        </div> 
                    </div>
                    </table>
                </div>


                <p> </p>
                {/* <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Достопримечательности</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Коченёвский краеведческий музей</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={a1} width={700}/>
                            </div>
                        </div>

                        <div class="attraction-2">
                            <div class="attraction-1-text">
                                <p>Районный Дом Культуры</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={a2} width={700}/>
                            </div>
                        </div>


                    </div> */}
            </section>
        </div>);
}

export default Kochenevskiy


/** 
function Kochenevskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region">
                    <h1>Коченевский район(Храмов).</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={flag} alt="Флаг Коченевского района" title="Герб" />
                    </div>
                    <div class="emblem">
                        <img src={emblem} alt="Герб Коченевского района" title="Герб" />
                    </div>
                    <div class="text">
                        <p>Население – 45627 человек. Административный центр – рабочий поселок Коченево. 
                        В 1925 году был образован Сибирский край, а в его составе — Коченёвский район Ново-Николаевского (Ново-Сибирского) округа. 
                        8 мая 1929 года был расформирован Бугринский район, 
                        и ранее входивший в его состав Прокудский сельсовет перешёл в состав Коченёвского района. 
                        В 1930 году Сибирский край был разделён на западную и восточную части, и район оказался в составе Западно-Сибирского края. 
                        В 1937 году район был включён в состав новообразованной Новосибирской области. Каинской Епархии Русской Православной Церкви. 
                        Архиерей – Митрополит Никодим. 24 декабря 2021 года президент благотворительного фонда святого мученика
                         Вонифатия Мезенцева Наталья Сергеевна передала Сибирскому Спасательному Центру ковчег с частицами мощей святых великомучеников , 
                         покровителей воинов : Георгия Победоносца, Димитрия Солунского и Варвары Илиопольской. После торжественного молебна перед личным 
                         составом ковчег был передан командиру ССЦ Конюкову Дмитрию Владимировичу. Постоянным местом пребывания святыни будет храм во имя 
                         Архистратига Михаила в р.п. Коченево.</p>
                    </div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches-titile">
                        <h2>Церкви Коченевского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм Собора архистратига Михаила и прочих Небесных Сил бесплотных р.п. Коченево.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Храм Собора архистратига Михаила и прочих Небесных Сил бесплотных р.п. Коченево." title="Храм1"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Kochenevskiy
*/