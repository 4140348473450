import React from 'react'
import flag from "./region/Karagatskiy/herb1.png"
import emblem from "./region/Karagatskiy/herb2.png"
import attraction_1_image from "./region/Karagatskiy/priroda1.jpg"
import church_1_image from "./region/Karagatskiy/h1.jpg"
import church_2_image from "./region/Karagatskiy/h2.jpg"
import aa1 from "./region/Karagatskiy/aa1.jpg"
import oo1 from "./region/Karagatskiy/oo1.jpg"
import admin from "./region/Karagatskiy/admin.jpg"





function Karagatskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Каргатский район</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={emblem} alt="Герб Каргатский район" title="Герб" />
                    </div>
                    <h1 className='head'>Каргатский район</h1>
                </div>
               
                <div class="about-region">
                    
                        <div class="inf-4">
                        
                                              
                        Образован в 1925 году, в 1937 году район был включён в состав
                            новообразованной Новосибирской области.
                            Граничит c Убинским, Чулымским, Кочковским и Доволенским районами Новосибирской области.                        На территории района находится Убинское озеро, основные реки: Каргат и Чулым.
                            В районе имеются запасы минеральных ресурсов для строительной сферы.<p></p>
                        
                        
                        
                        <div class='center-image'><img src={admin} width={700}/> </div>

                    </div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Храмы Каргатского района</h2>
                    </div>
                   
                   <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Собор святого пророка Илии, г. Каргат</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={600} />
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    Строительство храма началось в 2010 году. 24 апреля 2010 г. архиепископом 
                    Новосибирским и Бердским Тихоном освящен закладной камень и 
                    начались земляные работы, а 26 октября 2010 г. завершена  кирпичная кладка храма, 
                    начались отделочные работы. 4 декабря 2010 г. 
                    торжественно освящены и установлены купола и кресты храма. 
                    В январе 2012 г. началась роспись храма. Великое освящение совершилось 27 января 2013 г.


                    <div>Территория храма огорожена кованым забором на свайном фундаменте 
                        с тремя входами, вымощена гранитной брусчаткой. 
                        Входы на территорию, административное здание и храм украшают 11 мозаичных икон.</div>
                        
                        
                    <p><b>Адрес:</b> г. Каргат, ул. Советская 122А.</p>  
                    </div> 
                    </table>
                   
                   
                   
                    


                    <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            <p>Часовня Николая Чудотворца</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={370}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        Деревянная одноглавая часовня. Сооружена на средства частного благотворителя у автодороги М-51, неподалеку от г. Каргат.
                         Освящена 2 августа 2005. 
                        
                        
                        <p><b>Адрес:</b> Каргатский р-он, трасса М51, поворот на п. Форпост - Каргат.</p>  </div> 
                    </div>
                    </table>



                </div>

                <p> </p>
                <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Памятник природы областного значения «Волчья грива»</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={aa1} width={700}/>
                            </div>
                        </div>


                        <div class="attraction-2">
                            <div class="attraction-1-text">
                                <p>Озеро Убинское</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={oo1} width={700}/>
                            </div>
                        </div>


                    </div>
            </section>
        </div>);
}

export default Karagatskiy
















/*

function Karagatskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region">
                    <h1>Каргатский район (Кузьмина)</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={flag} alt="Флаг Карагатского района" title="Герб" />
                    </div>
                    <div class="emblem">
                        <img src={emblem} alt="Герб Карагатского района" title="Герб" />
                    </div>
                    <div class="text">
                        <p>Население 15 157[2] чел. Образован в 1925 году, в 1937 году район был включён в состав
                            новообразованной Новосибирской области.</p>
                    </div>
                </div>
                <div class="info-region">
                    <div class="attractions">
                        <div class-="attraction-title">
                            <h2>Достопримечательности Карагатского района:</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-text">
                                <p>Административный центр - город Каргат.</p>
                            </div>
                            <div class="attraction-image">
                                <img src={attraction_1_image} alt="Административный центр - город Карагат"
                                    title="Карагат" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="churches">
                    <div class="churches-title">
                        <h2>Храмы Карагатского района:</h2>
                    </div>
                    <div class="Church-1">
                        <div class="church-1-title">
                            <p>Собор святого пророка Илии.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Собор святого пророка Илии" title="Храм1" />
                        </div>
                    </div>
                    <div class="Church-2">
                        <div class="church-2-title">
                            <p>Часовня Николая Чудотворца.</p>
                        </div>
                        <div class="church-2-image">
                            <img src={church_2_image} alt="Часовня Николая Чудотворца" title="Храм2" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Karagatskiy


*/

