import React from 'react'
import emblem from "./region/Kupinskiy/he2.png"
import church_1_image from "./region/Kupinskiy/h1.png"
import church_2_image from "./region/Kupinskiy/h2.png"
import church_3_image from "./region/Kupinskiy/h3.png"
import church_4_image from "./region/Kupinskiy/h4.png"

import ad from "./region/Kupinskiy/ad.jpg"
import o1 from "./region/Kupinskiy/o1.jpg"
import o2 from "./region/Kupinskiy/o2.jpg"


function Kupinskiy() {
    return (
        
        <div class='main'>
            <div>
                
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region"> 
                <div class="flag">
                        <img src={emblem} width={150} alt="Флаг Баганск" title="Герб" />
                    </div>
                    <h1 className='head'>Купинский район</h1>
                </div>
                <div class="about-region">
                    <div class="inf-4">
                            Район образован в 1925 году в составе Барабинского округа Сибирского края.
                            В 1930 году Барабинский округ был упразднён, а район вошёл в состав нового 
                            Западно-Сибирского края.
                            В 1937 году район был включён в новообразованную Новосибирскую область.
                            Административный центр — город Купино.  Население 27 070 человек (2021 г.).                 
                    </div>
                    <p></p>
                    <div class='center-image'>
                    <img src={ad} width={600} alt="Купинский район" title="Купинский район" />
                    </div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Купинского района</h2>
                    </div>
                   
                    
                    
                   <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Храм святого апостола и евангелиста Луки, г. Купино
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={400}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        Церковь была построена на средства фонда имени императора Александра III и деньги, 
                        завещанные И.Д.Баевым и В.Н. фон Дервиз. Освящена 10 июня 1900 года в честь святого 
                        апостола и евангелиста Луки.
                        <div>
                        В годы гонений, в 1937 году церковь была закрыта. Кресты и купола были снесены, своды обрушены. 
                        Затем крышу починили и в здании располагались разные службы. 
                        Долгое время здесь находился дом пионеров. 
                        </div>
                        <div>
                        В 2000 году здание было возвращено Новосибирской епархии и началось его восстановление.
                         Храм реставрировался по проекту, сохранившемуся в Государственном архиве 
                         Томской области.
                         </div>
                         <div>
                        31 октября 2006 года состоялось освящение восстановленной церкви 
                        святого апостола и евангелиста Луки. 
                        Теперь возрожденный храм является главной святыней города Купино. 
                        </div>
                        <p><b>Адрес:</b> г. Купино, Новособорная площадь, 1.</p>
                        
                        </div> 
                    </div>

                    </table>

                   <table class='table-w'> 
                    <div class="church-2">
                        <div class="church-1-title">
                            Храм Преображения Господня, г. Купино
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={400}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        В 1980 г. православные верующие города приобрели для 
                        совместной молитвы дом по улице Банной. 
                        Так в городе Купино появилась первая вновь образованная православная община. 
                        <div>В 1987 году дом был переоборудован под храм. 20 июня 1987 г. 
                        митрополитом Гедеоном (Докукиным) храм был освящен в честь Преображения Господня.</div>
                        <div>В октябре 2001 года улица Банная была переименована в улицу Преображенскую в честь храма, расположенного на ней.</div>
                        <p><b>Адрес:</b> г. Купино, ул. Преображенская, 44.</p>
                        {/* <p> </p><p> </p><p> </p> */}

                          
                        </div> 
                    </div>
                    </table>
                    
                    
                    
                    <table class='table-w'> 
                    <div class="church-3">
                        <div class="church-1-title">
                            <p>ㅤ ㅤ ㅤ Храм Казанской иконы Божией Матери, с. Метелёво</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={400} /> 
                        </div>
                        
                        <div class ={"inf-4"}>
                        Церковь располагается в здании, которое выделила местная администрация. 
                            Здание было обустроено, и в октябре 2014 года был освящен храм в честь
                            Казанской иконы Божией Матери.
                        <div> </div>
                        
                        <p><b>Адрес:</b>с. Метелёво, ул. Центральная, 58.</p> 
                         <p> </p><p> </p><p> </p></div> 
                    </div>
                    </table>



                    <table class='table-w'>
                    <div class="church-4">
                        <div class="church-1-title">
                            <p>Часовня св. Феодора Томского, г. Купино.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_4_image} width={400}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        Часовня была освящена 17 сентября 2016 года — в день 130-летия г. Купино.

                        <p><b>Адрес:</b> г. Купино, ул. Розы Люксембург, 81а.</p>  </div> 

                    </div>

                </table>       
                </div>
                




                <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озеро Чаны</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={o1} width={700} alt="Озеро Чаны" title="Озеро Чаны" />
                            </div>
                        </div>

                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озеро Яркуль</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={o2} width={700} alt="Озеро Яркуль" title="Озеро Яркуль" />
                            </div>
                        </div>


                    </div>
            </section>
        </div>);
}

export default Kupinskiy

/*
function Kupinskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region">
                    <h1>Купинский район (Голубь)</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={flag} alt="Флаг" title="Герб" />
                    </div>
                    <div class="emblem">
                        <img src={emblem} alt="Герб" title="Герб" />
                    </div>
                    <div class="text">
                        <p> Административный центр — город Купино.
                            Население 27 070 чел. (2021)
                            Район образован в 1925 году в составе Барабинского округа Сибирского края. В 1930 году Барабинский округ был упразднён, а район вошёл в состав нового Западно-Сибирского края. В 1937 году район был включён в новообразованную Новосибирскую область.
                            Центральное благочиние Карасукской епархии, Архиерей – Епископ Филипп.</p>
                    </div>
                    <div class="admin-1-image">
                        <img src={attraction_1_image} alt="амин" title="амин" />
                    </div>
                </div>
                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2>Объекты природного наследия:</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озёра Чаны, Яркуль (последнее известно своими лечебными свойствами при заболеваниях кожи, опорно-двигательного аппарата, органов дыхания).</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="churches">
                    <div class="churches-titile">
                        <h2>Церкви Кочковского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм святого апостола и евангелиста Луки в г.Купино.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Храм святого апостола и евангелиста Луки в г.Купино" title="Храм1" />
                        </div>
                    </div>
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>Храм Преображения Господня в г.Купино.</p>
                        </div>
                        <div class="church-2-image">
                            <img src={church_2_image} alt="Храм Преображения Господня в г.Купино" title="Храм2" />
                        </div>
                    </div>
                    <div class="church-3">
                        <div class="church-3-title">
                            <p>Храм Казанской иконы Божией Матери в с.Метелево.</p>
                        </div>
                        <div class="church-3-image">
                            <img src={church_3_image} alt="Храм Казанской иконы Божией Матери в с.Метелево" title="Храм3" />
                        </div>
                    </div>
                    <div class="church-4">
                        <div class="church-4-title">
                            <p>Часовня св. Феодора Томского в г.Купино.</p>
                        </div>
                        <div class="church-4-image">
                            <img src={church_4_image} alt="Часовня св. Феодора Томского в г.Купино" title="Храм4" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Kupinskiy
*/