import React from 'react'
import { BiExit } from "react-icons/bi";
import "./InformContact.css";
import { Link } from "react-router-dom";
import image from "../Contact/ourclass2.jpg";

function InformContact() {
  return (
    <div className='MainClassInformContact'>
        <p></p>
        "Путеводитель по Новосибирской митрополии" подготовлен к 100-летию Новосибирской епархии
        по благословению Высокопросвященнейшего Никодима, митрополита Новосибирского и Бердского. 
        <div>Сайт будет пополнятся информацией о храмах, монастырях, 
          святынях Новосибирской митрополии, а также статьями по сибирскому краеведению. </div>
          
          <div>В разделе контакты указан адрес для обратной связи, по которому Вы можете написать свои замечания и предложения.</div>
          <p></p>
          <div>Над созданием сайта работали ученики Православной гимназии во имя Преподобного 
            Сергия Радонежского Новосибирского Академгородка.</div>
            <p></p><div className="center-image">
                  <img className src={image} width="400" />
                </div>
            
          <Link to="/">
        <div class="BiExit1">
          <BiExit />
        </div>
      </Link>
        </div>
  )
}

export default InformContact