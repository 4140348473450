import React, { useState } from "react";
import "./Front.css";
import { AiOutlineSearch } from "react-icons/ai";
import { BsJustify } from "react-icons/bs";
import { Link } from "react-router-dom";
import image from "../Components/ourclass2.jpg";
import MainCarousel from "../Carousel/MainCarousel";
import ghoul from "./Coat_of_arms_of_Novosibirsk_oblast.svg.png";

const Front = () => {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <header>
      <div class="html">
        <div class="header">
          
          {/* <BsJustify
            onClick={() => setShow(!show) ?? setIsActive(!isActive)}
            class="BsJustify"
          />
          {show && (
            <table>
              <div class="off-top">
                <p>  </p>
                <div>
                  <p>
                    Над  проектом  работали  ученики  Православной  гимназии во
                    имя преподобного  Сергия  Радонежского{" "}
                  </p>{" "}
                </div>
                <div className="center-image">
                  <img className src={image} width="400" />
                </div>
              </div>{" "}
            </table>
          )} */}






          <div class="Header" style={{ filter: isActive ? "" : "blur(20px)" }}>
            {" "}
            <p>
              Путеводитель <div>по Новосибирской митрополии</div>{" "}
              <div>Русской Православной Церкви</div>
            </p>{" "}
          </div>
          <div class="off-top" style={{ filter: isActive ? "" : "blur(20px)" }}>
            {" "}
            К   100-летию Новосибирской епархии{" "}
          </div>
          <div class="off-top" style={{ filter: isActive ? "" : "blur(20px)" }}>
            {" "}
          </div>
          <div class="off-top" style={{ filter: isActive ? "" : "blur(20px)" }}>
            (1924-2024)
          </div>
          {/* <div class="search" style={{filter: isActive ? '' : 'blur(20px)'}}>
          <AiOutlineSearch />
          <input className="k" placeholder="поиск"/>
        </div> */}
        </div>

        <div class="info" style={{ filter: isActive ? "" : "blur(20px)" }}>
          
          <div
            class="contain-1"
            style={{ filter: isActive ? "" : "blur(20px)" }}
          >
            <a class="sem" href="/ep">
              <div class="inf-2">
                {" "}
                Из истории <div>Новосибирской епархии</div>
              </div>
            </a>
          </div>


          <div
            class="contain-1"
            style={{ filter: isActive ? "" : "blur(20px)" }}
          >
            <a class="sem" href="/Mainmap">
              <div class="inf-2">
                Храмы <div>Новосибирской митрополии</div>
              </div>
            </a>
          </div>


          <div
            class="contain-1"
            style={{ filter: isActive ? "" : "blur(20px)" }}
          >
            <a class="sem" href="/epnsk">
              <div class="inf-2">Храмы г. Новосибирска </div>
            </a>
          </div>


          <div
            class="contain-1"
            style={{ filter: isActive ? "" : "blur(20px)" }}
          >
            <Link class="sem" to="sv">
              <div class="inf-2">Святыни Новосибирской митрополии </div>
            </Link>
          </div>



          <div
            class="contain-1"
            style={{ filter: isActive ? "" : "blur(20px)" }}
          >
            <a class="sem" href="/sobor">
              {" "}
              <div class="inf-2">
                Собор святых <div>Новосибирской митрополии</div>{" "}
              </div>
            </a>
          </div>


          <div
            class="contain-1"
            style={{ filter: isActive ? "" : "blur(20px)" }}
          >
            <a class="sem" href="/kraeved">
              {" "}
              <div class="inf-2">
                Материалы по церковному краеведению <div></div>{" "}
              </div>
            </a>
          </div>



        </div>
      </div>
      <div className="ClassContact">
      {/* <div className="NsuMetropolisTag">Путеводитель по Новосибирской митрополии Русской Православной Церкви</div> */}
        <a className="AConcact" href="/Contact">О проекте</a>               
        <a className="AConcact" href="/Contact1">Контакты</a> 
       
      </div>


      <div className="ClassTop">
      {/* <div className="NsuMetropolisTag">Путеводитель по Новосибирской митрополии Русской Православной Церкви</div> */}
        <div className="AConcact">По благословению митрополита Новосибирского и Бердского Никодима</div>               
        
       
      </div>
    </header>
  );
};

export default Front;
