import React from 'react'
import emblem from "./region/Krasnozerskiy/he.png"
import church_1_image from "./region/Krasnozerskiy/h1.jpg"
import church_2_image from "./region/Krasnozerskiy/h2.jpg"
import church_3_image from "./region/Krasnozerskiy/h3.jpg"
import church_4_image from "./region/Krasnozerskiy/h6.jpg"
import ad from "./region/Krasnozerskiy/ad.jpg"
import o1 from "./region/Krasnozerskiy/o1.jpg"
import a2 from "./region/Krasnozerskiy/a2.jpg"
import a3 from "./region/Krasnozerskiy/a3.jpg"


function Krasnozerskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Краснозерский район</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={150} alt="Флаг Баганск" title="Герб" />
                    </div>
                    <h1 className='head'>Краснозерский район</h1>
                </div>
                
                        <div class="inf-4">
                        Район был образован 24 сентября 1925 года как Карасукский 
                        с центром в селе Карасукском в составе Славгородского округа Сибирского края. 
                        10 апреля 1933 года село Карасукское было переименовано в Краснозёрское, а район — 
                        в Краснозёрский в составе Западно-Сибирского края, Карасукским районом стал именоваться соседний, 
                        до этого Чёрно-Курьинский район
                          <div>Административный центр — рабочий посёлок Краснозёрское.
                           Район расположен на юго-западе Новосибирской области. 
                            Граничит с Кочковским, Доволенским, Здвинским и Карасукским районами, 
                            на юге с Алтайским краем.

                            Население — 28300 человек (2021 г.).</div>
                            <p></p>
                            
                           
                    </div>
                    <div class="center-image">
                <img src={ad} width={700} alt="Краснозерский р-н" title="Краснозерский район" />
                      </div>

                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Церкви Краснозерского района</h2>
                    </div>
                   
                    
                   
                   
                   
                    


                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Церковь святого великомученика и целителя Пантелеимона, пос. Краснозерское
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={450}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        Церковь святого великомученика и целителя Пантелеимона на территории санатория 
                        «Краснозерский» была заложена в декабре 2001 года. Освящена в октябре 2006 года. 
                        Деревянная церковь, клеть под двухскатной крышей, с небольшой трапезной и 
                            крыльцом. 
                        
                        <p><b>Адрес:</b> пос. Краснозерское, ул. Санаторий, 1/4.</p>
                         
                        </div> 
                    </div>
                    </table>

<p></p>
                    <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            Храм во имя Преподобного Сергия Радонежского, пос. Краснозерское
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={450}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        Приход зарегистрирован в сентябре 1997 года. 
                        Храм был обустроен из одноэтажного дома, строительство велось в 1998-1999 годах.
                        К зданию пристроена небольшая колоколенка.

                        <div>Имеется православная библиотека, видеотека и зал, 
                        оборудованный для занятий приходской воскресной школы и проведения бесед.</div>
                         <p><b>Адрес:</b> пос. Краснозерское, ул. Кирова, 54. </p> 
                        </div> 
                    </div>
                    </table>



<p></p>
                    <table className='table-w'>
                    <div class="church-2">
                        <div class="church-1-title">
                            Храм в честь Успения Пресвятой Богородицы, с. Половинное
                        </div>
                        <div class="church-1-image">
                            <img src={church_4_image} width={450}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        
                        <p><b>Адрес:</b> с. Половинное, ул. ​Тракторная, 18. </p> 
                        </div> 
                    </div>
                    </table>


<p></p>
                    <table className='table-w'>
                    <div class="church-2">
                        <div class="church-1-title">
                            Храм в честь Покрова Пресвятой Богородицы, с. Веселовское
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={450}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        Храм был освящен в 2005 году. 

                        <p><b>Адрес:</b> Краснозерский район, с. Веселовское. </p> 
                        </div> 
                    </div>
                    </table>
                    

                </div>

                
                <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Достопримечательности Краснозерского района</h2>
                        </div>
                        
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Озеро Островное</p>
                            </div>
                            <div class="attraction-1-image">
                                {<img src={o1} width={700} alt="Озеро Островное" title="Озеро Островное" />}
                            </div>
                        </div>


                        {/* <div class="attraction-2">
                            <div class="attraction-1-text">
                                <p>Памятник Славным сынам России</p>
                            </div>
                            <div class="attraction-1-image">
                                {<img src={a1} width={700} alt="Озеро Горькое" title="Озеро" />}
                            </div>
                        </div> */}

                        <div class="attraction-3">
                            <div class="attraction-1-text">
                                <p>Памятник природы областного значения Степная катена</p>
                            </div>
                            <div class="attraction-1-image">
                                {<img src={a2} width={700} alt="Степная катена" title="Степная катена" />}
                            </div>
                        </div>

                        <div class="attraction-4">
                            <div class="attraction-1-text">
                                <p>Памятник природы областного значения
                                     Зонально-разнотравно-ковыльная степь</p>
                            </div>
                            <div class="attraction-1-image">
                                {<img src={a3} width={700} alt="Озеро Горькое" title="Озеро" />}
                            </div>
                        </div>



                 </div>
            </section>
        </div>);
}

export default Krasnozerskiy


