import React from "react";
import "./nsk.css";
import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import sv_Domna from './img_sobor/sv_Domna.jpg'
import sv_Domna2 from './img_sobor/sv_Domna-2.jpeg'
import sv_Domna3 from './img_sobor/Domna_chas.jpg'
import sv_Domna4 from './img_sobor/sv_Domna_4.jpg'





function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <title>Святая блаженная Домна Каинская и Томская</title>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Святая блаженная Домна Каинская и Томская</b>
        </div><p></p> 
      


                  {/*  */}
                    <table>
                        <div class="sv-title">
                            <p></p>
                        </div>
                        <div class="church-1-image">
                            <img src={sv_Domna} width={280}
                                alt="Священномученик Илия Березовский"
                                title="Священномученик Илия Березовский" />
                        </div>
                        
                        <div class ={"inf-5"}>
                        Достоверных сведений о том, кто 
                        такая святая блаженная Домна Томская (Домна Карповна, как ее именовали люди) и где именно она родилась, нет. 
                        В статейном списке Томской 
                        экспедиции ссыльных, который имел у себя 
                        Преосвященный епископ 
                        Бийский Владимир (Петров), Домна значится как Мария 
                        Слепченко, судимая в Полтавской губернии за бродяжничество и сосланая в Сибирь на 
                        поселение. Согласно документам переписи, составленным в 1834 году, Марие 
                         было 30 лет, из чего следует, что она родилась в 1804 году. Приписана она была к селу
                         Иткуль 
                        Каинского округа Томской губернии, в котором, впрочем, никогда не жила. </div>
                       
                          <div class ={"inf-5_2"}>Основным источником сведений о блаженной Домне являются материалы, собранные и 
                          опубликованные в 1883 году протоиереем Николаем Митропольским, который близко знал ее. Отец Николай 
                          служил в селе Вознесенском 
                          Каинского округа, а затем и в самом городе Каинске. В село Вознесенское блаженная Домна каждый 
                          год приходила летом на ярмарку и до осени жила в нем и окрестных селениях.</div>
                          
                          <div class ={"inf-5_2"}>Из повествования протоиерея Николая можно узнать, что была блаженная Домна малоросиянкой, 
                          родилась в дворянской семье, но рано осиротела и 
                          воспитывалась в доме своей тети. Она была хорошо образована, владела иностранными языками. 
                          Придя в возраст и узнав, что родные хотят ее насильно выдать замуж, ушла из дома. 
                          Под именем Мария Слепченко пыталась поступить в монастырь, но ее не приняли. 
                          Она говорила об этом так: «Я уж много ходила по монастырям, да нигде не принимают, 
                          везде гонят, да наконец сослали в Сибирь». 
                          В Полтаве была арестована за бродяжничество как «непомнящая родства». 
                          По решению суда под именем Марии Слепченко была сослана в Сибирь, в село Иткуль Каинского округа 
                          Томской губернии. 
                          Святую Домну отец Николай впервые увидел в июле 1856 года, значит можно думать что она поселилась 
                          в Каинском уезде примерно в это время. </div>
                          <div class="church-1r1-image"><table><tr>
                            <img src={sv_Domna2} width={320}
                                alt="Святая блаженная Домна"
                                title="Святая блаженная Домна" /></tr>
                        <tr><div class='podpis'>Святая блаженная Домна. <div> 
                          </div>Литография XIX в.</div></tr></table></div>
                          <div class ={"inf-5_2"}>Поселившись в г. Каинске (сегодня – город Куйбышев в Новосибирской области), Домна приняла на 
                          себя подвиг юродства. Постоянного жилища она не имела, ночи часто проводила под открытым небом, уединяясь для молитвы и духовного пения.
                            
                          Одеяние подвижницы состояло из множества больших узлов, которые она плела из старой одежды, тряпок, 
                          ремней, веревок. Она часто перебирала эти узлы, словно четки, стараясь скрыть от окружающих свою непрестанную молитву. 
                          Кроме того, в маленьких узелках и пакетиках ее рубища хранились 
                           битые стекла, камни, щепки, опилки, кусочки сахара, и все это святая раздавала окружающим с иносказательным смыслом.
                            Всю хорошую одежду, которую ей дарили, немедленно раздавала нищим. Однажды епископ Томский Порфирий (Соколовский), 
                            почитавший блаженную, пожаловал ей новую архиерейскую шубу со своего плеча. С благодарностью блаженная Домна 
                            надела подарок, но через два часа отдала нищим.
                             </div>
                            <div class ={"inf-5_2"}>Денег подвижница никогда не просила и почти
                            никогда их не брала: «На что мне их?» – говорила святая. Но очень настойчиво и усердно Домна 
                            Карповна выпрашивала хлеб, булки, молоко и другую еду, чтобы накормить странников и кошек 
                            с собаками.</div>
                            <div class ={"inf-5_2"}>В храме блаженная горячо и усердно молилась со слезами, но если замечала свидетелей своей молитвы – 
                            тут же возвращалась 
                            к своему привычному поведению, юродствуя ради Христа. Протоиерей Николай Митропольский пишет об этом так:
                            
                            «Юродствовала Домна Карповна и в церкви во время богослужений, — и тем более, чем было больше 
                            молящихся: переходила с места на место, разговаривала, пела, гасила свечи, переставляла их с места 
                            на место, некоторые снимала и клала в свои узлы. </div>
                            <div class ={"inf-5_2"}>Совсем не так держала себя в церкви в будни, 
                            когда не было народа. Видали врасплох, что Домна 
                            Карповна, ставши на колена, где нибудь в стороне в 
                            собственном смысле молилась и не юродствовала: то 
                            она погрузившись в созерцание величия и благости 
                            Божией, казалась отрешившеюся от земли, то, сознавая свою немощь и греховность, проливала горькие 
                            слезы».
                          </div>
                          <div class ={"inf-5_2"}>В Томске, куда перебралась Домна Карповна из Каинска в 1860-х годах, она жила, 
                          где Бог приведет: часто ночевала на улице, несмотря ни на погоду, ни на 
                          время года. Зная, как тяжело заключенным в томском полицейском участке, старица нередко приходила туда и громко пела 
                          духовные песни, 
                          за что ее также задерживали. Узнав об этом, ее почитатели приносили ей еду, которую она с радостью раздавала арестантам. 
                          Для заключенных полицейской тюрьмы пребывание среди них 
                          блаженной было настоящим праздником: томские купцы, а более того купчихи, узнав об аресте Домны Карповны,
                           целыми коробами посылали для 
                          нее пироги, булки, блины, оладьи, чай 
                          и сахар, а та в свою очередь всё это 
                          раздавала арестантам. Когда же Домна 
                          Карповна выходила из-под ареста, то 
                          ее соузники со слезами провожали ее 
                          и в простоте сердца желали поскорее 
                          опять попасть за решетку. </div>
                          <div class ={"inf-5_2"}> «Научите спасаться!» – часто обращалась святая Домна ко многим, напоминая тем самым о 
                          главной цели жизни каждого человека и призывая к духовному бодрствованию, к соблюдению 
                          заповедей Христовых.
                          Смиренно перенося насмешки и оскорбления, терпя холод и зной, нося свои неподъемные вериги, святая 
                          Домна умерщвляла свою плоть, стяжав от Бога дар прозорливости. </div>
                          <div class="church-1r1-image"><table><tr>
                            <img src={sv_Domna3} width={340}
                                alt="Святая блаженная Домна"
                                title="Святая блаженная Домна" /></tr>
                        <tr><div class='podpis'>Часовня в честь блаженной Домны <div> 
                          </div></div></tr></table></div>
                          <div class ={"inf-5_2"}> Домна была хорошо знакома со святым старцем Феодором Томским,
                           после кончины 
                          которого она рассказала купцу 
                          Семену Феофановичу Хромову, 
                          глубоко чтившему старца, что 
                          однажды святой праведник 
                          одной молитвой исцелил ее от 
                          тяжелой болезни </div>
                         
                          <div class ={"inf-5_2"}>Домна Карповна преставилась ко Господу в 1872 году 16/29 декабря 
                          (по другим сведениям — 15 октября). Святую погребли в Томске 
                                на территории Иоанно-Предтеченского женского монастыря при большом стечении народа и духовенства. 
                                В 1870 году на территории монастыря была 
                                построена часовня, которая после 
                                преставления блаженной была названа в ее честь. В 1952 
                                году часовня, как и все монастырские 
                                храмы, была разрушена, впоследствии здесь был построен технологический институт. </div>
                                
                          
                          <div class ={"inf-5_2"}>В 1996 году на территории бывшего монастыря была вновь построена часовня святой блаженной Домны.</div>
                          <div class ={"inf-5_2"}>В 1984 году подвижница была прославлена как блаженная при 
                          установлении празднования Собора Сибирских святых. </div>
                          <div class ={"inf-5_2"}></div>
                          <div class ={"inf-5_2"}>12 марта на заседании Священного Синода Русской Православной Церкви под председательством 
                          Святейшего Патриарха 
                            Московского и всея Руси Кирилла было принято 
                            решение включить в поименный список Собора святых Новосибирской митрополии имя святой блаженной Домны Томской.</div>
                            <p></p>
                            
                            <div class ={"inf-5_2"}>Литература: </div>
                          <div class ={"inf-5_2"}>1. <i>Протоиерей Николай Митропольский.</i> Юродивая Домна Карповна. М. 1897. 
                          (То же: Томские епархиальный ведомости. 1883. № 6, № 9)</div>
                          <div class ={"inf-5_2"}>2. <i>Протоиерей Михаил Путинцев.</i> Юродивая Домнушка // Томские епархиальные ведомости. 1882. № 16. </div>
                          <div class ={"inf-5_2"}>3. <i>Протоиерей Михаил Путинцев.</i> Сказания о некоторых сибирских подвижниках благочестия 
                          // Душеполезное чтение. 1886. Часть 2.</div>
                          <div class ={"inf-5_2"}>4. <i>Священник Сергий Голубцов.</i> Спасение через юродство 
                          // Журнал Московской Патриархии. 
                          2017. № 6.</div>
                          
                          
                     
                    </table>
                     <p></p>
                    <div className="center-image"><img src={sv_Domna4} width={700}
                                alt="Святая блаженная Домна"
                                title="Святая блаженная Домна" /></div>

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
