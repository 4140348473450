

import React from 'react'
import flag from "./region/Kuybishevskiy/herb1.png"
import emblem from "./region/Kuybishevskiy/gerb1.png"
import attraction_1_image from "./region/Kuybishevskiy/r1.jpg"
import church_1_image from "./region/Kuybishevskiy/h2.jpg"
import church_2_image from "./region/Kuybishevskiy/hh2.JPG"
import ad from "./region/Kuybishevskiy/ad.jpg"
function Kuybishevskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Куйбышевский район</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={170} alt="Герб Куйбышевский район" title="Герб" />
                    </div>
                    <h1 className='head'>Куйбышевский район</h1>
                </div>
                
                <div class="about-region">
                    
                    <div class="inf-4">
                   
                        Административный центр — город Куйбышев.
                           Район выделен в 1936 году из Барабинского района и вошел в состав Западно-Сибирского края.
                           В 1937 году район был включён в состав вновь образованной Новосибирской области.
                            В 2018 году район вошёл в состав Барабинско-Куйбышевской агломерации. <p></p>
                           <div class='center-image'> <img  src={ad} width={800} alt="Куйбышевский район" title="" /></div>
                    </div>
                </div>
                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2></h2>
                        </div>
                    </div>
                    <div class="attraction-1">
                        <div class="attraction-1-text">
                            <p></p>
                        </div>
                    
                    </div>
                </div>
                <div class="churches">
                <div class="churches title">
                        <h2 className='churches-title'>Церкви Куйбышевского района</h2>
                    </div>
                   

                    <table class='table-w'>
                    <div class="church-2">
                        <div class="church-1-title">
                            <p>Кафедральный собор в честь Нерукотворного Образа Христа Спасителя</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_1_image} width={600} alt="Церковь Рождества Иоанна Предтечи, 1904 года постройки (г. Куйбышев)" title="Храм2" />
                        </div>

                        <div class ={"inf-4"}>
                        История храма началась давно. Освящение Спасского собора состоялось в 1804 г. с благословения 
                        архиепископа Тобольского Антония.
                        Храм был трехпрестольным: главный престол был освящен в честь Образа Спаса Нерукотворного, 
                       а два придела были освящены в честь святителя Николая Чудотворца  
                        и в честь Покрова Пресвятой Богородицы.


                        <div>В годы гонений собор был закрыт. Постановление о его закрытии поступило из Новосибирска 
                        в 1938 г. 
                            А спустя год, в 1939 году храм был разобран.  
                            </div> 

                        <div>Восстановление святыни началось в 2000-е годы. 
                            В 2014 году было составлено обращение с подписями горожан в пользу 
                            восстановления собора. В 2019 году обнаружен его фундамент и древний погост.

                            В августе 2020 года состоялось освящение основания, и менее чем через год были уже 
                            возведены стены. За два года величественный собор был построен. Высота главного купола с крестом составила 40 метров, 
                            высота шпиля на колокольне с крестом – 46 метров. Был изготовлен 
                            подбор из 11 колоколов. </div>
                          <div>   Собор был освящен 14 августа 2022 года. 
                            На его освящение для сослужения митрополиту Никодиму прибыли епископы Новосибирской 
                            митрополии. 
                             </div>

                         <div> </div>
                             <p><b>Адрес:</b> г. Куйбышев, ул. Краскома, 4. </p>
                        </div>
                    </div>
                    </table>



                   
                    <table class='table-w'>
                    <div class="church-2">
                        <div class="church-1-title">
                            <p>Церковь Рождества Иоанна Предтечи, г. Куйбышев</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_2_image} width={600} alt="Церковь Рождества Иоанна Предтечи, 1904 года постройки (г. Куйбышев)" title="Храм2" />
                        </div>

                        <div class ={"inf-4"}>
                        18 ноября 1904 года архиепископ Томский и Барнаульский Макарий (Невский) освятил новосооруженный храм во имя святого Пророка и Предтечи Господня Иоанна.
                         На колокольне храма размещалось шесть колоколов, самый большой из них весил 1697 кг.


                        <div>Храм для посещения верующих был открыт до 1940 года. В 1940 году Новосибирским 
                            Облисполкомом было принято решение от 29 июня за № 161 о закрытии храма.
                             После закрытия церковь подверглась капитальной перестройке: были разрушены купол, 
                             семь луковичных глав, колокольня, иконостас, люстра-паникадило. 
                            Южный фасад позднее был закрыт кирпичной пристройкой, часть окон заложена кирпичом.</div> 

                        <div>16 февраля 1987 г. решением Новосибирского Облисполкома за № 53 Иоанно-Предтеченская церковь 
                            была поставлена на государственную охрану как памятник архитектуры регионального значения.
                             В 1992 году храм был возвращен Русской Православной Церкви.
                        В 1993 году храм Рождества Иоанна Предтечи освящен Преосвященнейшим Тихоном, епископом 
                        Новосибирским и Барнаульским.
                         В 1995 году восстановлена колокольня. </div>

                         <div> В 2007 году по распоряжению городской администрации приходу было передано здание приходского училища, 
                            построенное на средства А.И. Шкроевой в 1906 году. Во времена советской власти там было помещение средней школы, затем Станции юных техников. 
                            После передачи здания приходу в нем были размещены воскресная школа, ризница, библиотека, трапезная, столярная мастерская и мастерская церковного художника. 
                            В 2008 году была разобрана пристройка и храм обрел свой первозданный вид. 
                            В 2009 году на территории храма началось строительство административного корпуса прихода. 
                             В 2010 году закончена реставрация фасадов храма.</div>
                             <p><b>Адрес:</b> г. Куйбышев, ул. Пугачева, 2. </p>
                        </div>
                    </div>
                    </table>
                   
                   
                   
                   
                   
       
                    


                    






                    
                </div>


                



            </section>
        </div>
    );
}

export default Kuybishevskiy








/*
import React from 'react'
import flag from "./region/Kuybishevskiy/herb1.png"
import emblem from "./region/Kuybishevskiy/herb2.png"
import attraction_1_image from "./region/Kuybishevskiy/gorod.png"
import church_1_image from "./region/Kuybishevskiy/h1.png"
import church_2_image from "./region/Kuybishevskiy/h2.png"
function Kuybishevskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region">
                    <h1>Куйбышевский район (Квон)</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={flag} alt="Флаг" title="Герб" />
                    </div>
                    <div class="emblem">
                        <img src={emblem} alt="Герб" title="Герб" />
                    </div>
                    <div class="text">
                        <p>Административный центр — город Куйбышев.
                            Район выделен в 1936 году из Барабинского района и вошёл в состав Западно-Сибирского края. В 1937 году район был включён в состав вновь образованной Новосибирской области.
                            В 2018 году район вошёл в состав Барабинско-Куйбышевской агломерации.
                            Каинская епархия, Архиерей - Митрополит Никодим.</p>
                    </div>
                </div>
                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2></h2>
                        </div>
                    </div>
                    <div class="attraction-1">
                        <div class="attraction-1-text">
                            <p></p>
                        </div>
                        <div class="attraction-1-image">
                            <img src={attraction_1_image} alt="амин" title="амин" />
                        </div>
                    </div>
                </div>
                <div class="churches">
                    <div class="churches-titile">
                        <h2>Церкви Куйбышевского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Церковь Иконы Божией Матери Всех Скорбящих Радости (г. Куйбышев).</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Церковь Иконы Божией Матери Всех Скорбящих Радости (г. Куйбышев)." title="Храм1" />
                        </div>
                    </div>
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>Церковь Рождества Иоанна Предтечи, 1904 года постройки (г. Куйбышев)</p>
                        </div>
                        <div class="church-2-image">
                            <img src={church_2_image} alt="Церковь Рождества Иоанна Предтечи, 1904 года постройки (г. Куйбышев)" title="Храм2" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Kuybishevskiy

*/



