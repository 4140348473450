import React from 'react'
import church_1_image from "./region/Dovolenskiy/hh1.jpg"
import church_2_image from "./region/Dovolenskiy/hh2.jpg"
import church_3_image from "./region/Dovolenskiy/h3.png"
import church_4_image from "./region/Dovolenskiy/h4_.jpg"
import church_5_image from "./region/Dovolenskiy/h7.jpg"

import flag from "./region/Dovolenskiy/herb2.png"
import aa2 from "./region/Dovolenskiy/aa2.jpg"
import admin from "./region/Dovolenskiy/admin.jpg"
import { TransitioningPropertyValue } from 'maplibre-gl'
function Dovolenskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Доволенский район</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={flag} width={160} alt="Герб Доволенский р-н" title="Герб" />
                    </div>
                    <h1 className='head'>Доволенский район</h1>
                </div>
               
                <div class="about-region">
                    
                        <div class="inf-4">
                        <p>
                        Доволенский район образован в 1930 году, расположен в южной части Новосибирской области. 
                        
                        Граничит с Краснозерским, Здвинским, Каргатским, Кочковским и Убинским 
                        районами области, площадь 4,4 тыс.кв.км.
                         
                        <div>Территория района расположена на расстоянии 312 км от 
                            областного центра города Новосибирска и 107 км от ближайшей 
                            железнодорожной станции Каргат.</div>
                            <p></p>
                            <div className='center-image'>
                            <img src={admin} width={800}/>
                            </div>
                        
                        </p>
                    </div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Храмы Доволенского района</h2>
                    </div>
                   


                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Храм Преподобного Сергия Радонежского, с. Довольное
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={500}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                    
                        Храм построен на месте одноименного старого храма, который 
                            украшал это село до революции. точная дата строительства храма в с. 
                            Довольном неизвестна. Первая деревянная церковь была уничтожена пожаром, 
                            и в 1908 году храм был построен заново на новом месте, деревянный, 
                            на каменном фундаменте. Разрушен в 1941. <div>История восстановления храма 
                            началась в 1993 году. Под церковь отдали здание оранжереи. Его перестроили, и 
                            уже летом 1994 начались службы, которые до 1998 года совершали 
                            приезжавшие священники. В 2000 году прихожане выступили с инициативой 
                            строительства нового храма. 
                            В 2003 году администрацией района был выделен участок и начато строительство. 
                            Вновь построенный храм был освящен 14 апреля 2019 года. </div>  
                        <div>В 2007 году недалеко от храма нашли место упокоения останки 18-ти 
                        священнослужителей, зверски замученных большевиками в 1920-30-е годы, 
                        которые были найдены близ с. Волчанка Доволенского района
                        и здесь перезахоронены.</div>
                        
                        <p> 
                        
                        
                        </p>
                        
                        <p><b>Адрес:</b> Доволенский район, с. Довольное, ул. Кооперативная, д. 25.</p></div> 
                    </div>
                    </table>


                    <p></p>
                    <table class='table-w'>
                <div class="church-1">
                    <div class="church-1-title">
                        Церковь Вознесения Господня, с. Индерь
                    </div>
                    <div class="church-1-image">
                        <img src={church_4_image} width={500}/>
                    </div>
                    
                    <div class ={"inf-4"}>
                   
                    Вознесенская церковь в с. Индерь была построена в 1885 году, закрыта и разобрана в 1930-е гг.  
                    <div>Новый храм был построен и освящен в 2013 году.</div>
                                 
                    <p><b>Адрес:</b> Доволенский район, с. Довольное, ул. Кооперативная, д. 25.</p></div> 
                </div>
                </table>





                    <table class='table-w'>
                    <div class="church-2">
                        <div class="church-1-title">
                            Храм святителя Николая Чудотворца, с. Баклуши
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_1_image} width={500}/>
                        </div>
                    
                    <div class ={"inf-4"}>
                    Храм был открыт в селе Баклуши Доволенского района в 2008 году в приспособленном помещении. 
                    
                    <p><b>Адрес:</b> с. Баклуши, ул. Гагарина, 3.</p>  
                      </div>             
                    </div> 
                    </table>  
                   
                    
                    <p></p>
                    <table class='table-w'>
                    <div class="church-1">
                        <div class="church-2-title">
                            Храм в честь Казанской иконы Божией Матери, с. Волчанка
                        </div>
                        <table>
                        <div class="church-1-image">
                            <img src={church_2_image} width={500}/>
                        </div>
                        

                        <div class ="inf-4">
                        <p><b>Адрес:</b> с. Волчанка, ул. Центральная, 6</p></div> 
                        </table>              
                    </div>
                    </table>



                    <p></p>
                    <table class='table-w'>
                    <div class="church-1">
                        <div class="church-1-title">
                        Храм в честь Казанской иконы Божией Матери, с. Комарье
                        </div>
                        <div class="church-1-image">
                            <img src={church_5_image} width={450}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                       
                         <p><b>Адрес:</b> село Комарье, ул. Почтовая, 1.</p>
                        </div> 
                    </div>
                    </table>




                    

                </div>

                
                <div class="attractions">
                        <div class="attractions-title">
                            <h2>Достопримечательности Доволенского района</h2>
                        </div>
                        
                        
                        
                        
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Памятник природы областного значения «Покровская лесостепь»</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={aa2} width={700} />
                            </div>
                        </div>
                        
                        
                       
                       
                    </div>
                

            </section>
        </div>);
}

export default Dovolenskiy













/*

function Dovolenskiy() {
    return (
        <div class='main'>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Document</title>
            <section class="region">
                <div class="title-region">
                    <h1>Доволенский район (Шин).</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={flag} alt="Флаг Доволенского района" title="Герб" />
                    </div>
                    <div class="emblem">
                        <img src={emblem} alt="Герб Доволенского района" title="Герб" />
                    </div>
                    <div class="text">
                        <p>Административный центр — село Довольное. Население:15 500(2021).</p>
                    </div>
                </div>
                <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2>Достопримечательности Доволенского района:</h2>
                        </div>
                        <div class="attraction-1-text">
                            <p>Источник минеральной воды-аналога воде«Ессентуки №17» на территории санатория "Доволенский".</p>
                        </div>
                        <div class="attraction-2-text">
                            <p>Доволенский район входит в тройку наиболее привлекательных районов Барабинской зоны Новосибирской
                                области по многообразию животных и птиц (особенно водоплавающих), на которые разрешена
                                любительская охота. Доволенский биологический заказник; Памятник природы областного значения
                                «Индерский рям».</p>
                        </div>
                        <div class="attraction-3-text">
                            <p>Памятник природы областного значения «Покровская лесостепь».</p>
                        </div>
                        <div class="attraction-4-text">
                            <p>Памятник природы областного значения «Старогорносталевское займище».</p>
                        </div>
                        <div class="attraction-5-text">
                            <p> Памятник природы областного значения «Урочище Золотая нива».</p>
                        </div>
                        <div class="attraction-6-text">
                            <p> Санаторно-курортная зона озера «Доволенское»; Пресное озеро Доволенское (4 км от районного
                                центра).</p>
                        </div>
                        <div class="attraction-7-text">
                            <p>Пресное озеро Островное с биологически активной грязью (60 км от районного центра.</p>
                        </div>
                    </div>
                </div>
                

















                <div class="churches">
                    <div class="churches-title">
                        <h2>Храмы Доволенского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>с. Баклуши – Храм свт. Николая.</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Храм свт. Николая" title="Храм1"/>
                        </div>
                    </div>
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>с. Волчанка – Храм в честь Казанской иконы Божией Матери.</p>
                        </div>
                        <div class="church-2-image">
                            <img src={church_2_image} alt="Храм в честь Казанской иконы Божией Матери" title="Храм2"/>
                        </div>
                    </div>
                    <div class="church-3">
                        <div class="church-3-title">
                            <p>с.Довольное – Храм Преподобного Сергия радонежского.</p>
                        </div>
                        <div class="church-3-image">
                            <img src={church_3_image} alt="Храм Преподобного Сергия радонежского" title="Храм3"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default Dovolenskiy
*/