import React from "react";

import { BsJustify } from "react-icons/bs";
import { useState } from "react";
import {Link} from 'react-router-dom'
import {BiExit} from "react-icons/bi";
import Innocent from './img/AI_title.jpg';




function Ep() {
  const [show, setShow] = useState(false);
  const [isActive, setIsActive] = useState(true);

  return (
    <div class="nsk">
      {/* <div className="icons">
        <BsJustify onClick={() => setShow(!show) ?? setIsActive(!isActive)} />
        </div> */}
        <p></p>
        <div className="alle2" style={{ filter: isActive ? "" : "blur(20px)" }}>
        <b>Церковное краеведение <div>в нравственном воспитании человека</div></b>
        </div>  
        

                 
                    <div> <table>
                        <div class="sv-title">
                           
                        </div>
                        <div class ={"inf-5_podpis"}>Архимандрит Иннокентий (Просвирнин)</div>
                        <div class="church-1-image">
                            <img src={Innocent} width={280}
                                alt=""
                                title="" />
                        </div>
                       
                        <div class ={"inf-5"}>
                        Какое бы место и какое время мы ни воспоминали, какие бы дела рук человеческих мы ни рассматривали в истории нашего Отечества, мы все более убеждаемся, как велика зиждительная сила ЛЮБВИ. Любовь и назидает, и созидает. Движимые этой удивительной духовной силой, одни упражняются в любомудрии (философии), другие в филологии, иные — в зодчестве, шитье, добром ведении хозяйства или иных видах искусства, почерпая опыт у многоопытных отцов и матерей нашей Родины. Что-то передается генетически, когда развиваются и приумножаются таланты в детях и есть согласие в развитии рода. Что-то усваивается от добрых людей, износящих из сокровищницы сердца своего то или иное добро, 
                        любовь к тому или иному рукоделью.</div>
                       <div class ={"inf-5_2"}>Прослеживая связь времен по всем доступным нам признакам: и хронологическому, и географическому, и авторскому, и предметному, — мы видим взаимодействие определенных граней по каждому из этих признаков. В одних местах сила любви к определенному виду деятельности слабеет, в других процветает. Образно говоря, в истории каждого края можно наблюдать четыре стадии развития этой высшей сферы силы духа человеческого 
                        — весну, лето, осень и зиму.</div>
                       <div class ={"inf-5_2"}>Нередко приходится сожалеть, что само понятие о любви в наши дни выхолащивается из-за утраты любви к истории, к своему родному краю, к родным — и живым, и усопшим... Нередко мы много говорим и пишем о добре, развитии добродетелей в человеке, но не являемся добрыми, или, как говорили в старину, — добродетельными. Мы забываем о том, что ЛЮБОВЬ — это подвиг. Справедливость нередко утверждаем на виновности других, растрачивая «дух мирен» своей души во имя мнимых добродетелей на неосуществленные идеалы того или иного общества, того или иного края. Так, можно наблюдать, как скатываются наши современники до «плотского» понятия любви, 
                        забывая Любовь Божественную.</div>
                       <div class ={"inf-5_2"}>А между тем, знание своего края, своей Родины (краеведение) изначально предусматривало нравственные цели развития 
                        в системе воспитания личности.</div>
                        
                        
                       <div class ={"inf-5_2"}>Люди, не знающие своего края, далеки от реальности. Как правило, их жизненные позиции зиждятся на мнимости. Мы чаще всего мним — думаем, что знаем, а затем сомневаемся, 
                        подменяя реальное знание — мнимым, реальную веру — суеверием.</div>
                       <div class ={"inf-5_2"}>Здесь можно было бы возразить: о какой реальности речь?</div>
                       <div class ={"inf-5_2"}>Реально проявляет себя и дух созидания, дух ЛЮБВИ.</div>
                       <div class ={"inf-5_2"}>Реально проявляет себя и дух разрушения, дух зла.</div>
                       <div class ={"inf-5_2"}>Сколько зла принес дух разрушения, выразившийся в определенном отношении к памятникам истории и культуры во всех районах нашей страны. Теперь мы начали сознавать свою ущербность в утрате ключевых исторических понятий, и особенно понятий нравственных. Правда, это осознание развивается до определенного предела. Видите ли, мы «стесняемся», а точнее, не хотим прослыть невеждами (то есть не ведающими того или иного вопроса истории и культуры), а потому начинаем прибегать к косметическим средствам, создаем моды и действуем 
                        в духе нами же созданной моды...</div>
                       
                        <div class ={"inf-5_2"}>А о каком краеведении может идти речь, если попираются нравственные принципы жизни? Могилы заменяются колумбариями, якобы за недостатком земли. На местах священных могил наших предков нагребаем отходы производства своих же рук, утверждаем мнимое оправдание своим безнравственным действиям. Например, кладбище под Загорском. На кладбище строят микрорайоны, танцплощадки, футбольное поле. Можно привести много примеров для иллюстрации недолжного 
                        состояния священных мест захоронения.</div>
                        <div class ={"inf-5_2"}>При таком подходе к жизни всякое краеведение теряет смысл, как теряет смысл та или иная библиотека, тот или иной музей, если видеть в них, прежде всего, валютные ценности взамен ценностям духовным, нравственным. Но «любовь созидает и не падает» даже в этих условиях. «Любовь долготерпит, любовь не превозносится, не гордится, не ищет своего, не радуется неправде, сорадуется истине». И потому разрешите призвать участников краеведческой работы к такому взаимодействию в наступивший период нашей отечественной истории, которое своей зиждительной реальностью к своему делу по призванию исключало бы дурную мнимость, мнительность, воодушевляло на новые дела и созидание 
                        высокодуховной, нравственной жизни.</div>
                        <div class ={"inf-5_2"}>Знание своего края возможно лишь тогда, когда есть доступ к источникам. Сегодня он ограничен ввиду местечкового устройства системы музейного дела, библиотечного дела и архивного дела. Определенные идеологические отделы в центре, а тем более на местах, «в заботе» о чистоте идейной поколения нового общества решило не все выдавать интересующимся своей родной историей. Одни материалы — на специальном хранении ввиду их религиозного характера, другие уничтожены под предлогом реакционности, третьи не закомплектованы из-за элементарной лени работников, ответственных за научную организацию дела. Устранить этот изъян можно только совместными усилиями с применением всех средств современной оргтехники, заключив в единую факсимильную связь имеющихся в банке данных, которые, несомненно, 
                        в ближайшее время перерастут в банки знаний.</div>

                        <div class ={"inf-5_2"}>В тех областях и районах, где этих банков данных нет, надо их срочно создавать, пока живы носители духовной культуры нашей страны. 
                        Их немного осталось.</div>

                        <div class ={"inf-5_2"}>Краеведение сегодня — нравственная задача каждого мыслящего человека, независимо от его профессии и специализации. Когда будут среди нас носители цельных знаний, тогда возможно 
                        будет воспитывать нравственное поколение людей.</div>

                        <div class ={"inf-5_2"}>Наша отечественная история настолько богата духовным содержанием, что она способна удовлетворить, обогатить духовно, а значит, и нравственно, 
                        многих в этом разделенном и неспокойном мире.</div>

                        <div class ={"inf-5_2"}>И не только в нашей стране.</div>

                        <div class ={"inf-5_2"}>И не случайно на Россию смотрят многие народы как на источник нравственного возрождения.</div>

                        <div class ={"inf-5_2"}>Большую надежду возлагают на Россию в Греции, где с 1962 года продолжают интенсивно переводить творения русских святых отцов. 
                        Благодатного света из России ожидают в Европе.</div>

                        <div class ={"inf-5_2"}>Велика наша ответственность перед всеми народами, и особенно перед своим родным народом.</div>

                        <div class ={"inf-5_2"}>Настала пора опомниться, пересмотреть отношение к краеведению, исключить полумеры в решении всех проблем жизни, начиная вновь 
                        в полном смысле с научной организации дела.</div>

                        <div class ={"inf-5_2"}>Верю, что благожелательное отношение друг к другу вернет растраченную «на страже закона» любовь к своему родному очагу, 
                        к своему родному Отечеству.</div>

                        <div class ={"inf-5_2"}>Надеюсь, что наши министерства, и особенно Министерство просвещения, в системе нравственного воспитания личности найдут достойное место краеведению, 
                        ибо нельзя строить свою жизнь на основании, которого не ведаешь.</div>

                        <div class ={"inf-5_2"}>Дай Бог, чтобы труженики литературного краеведения и краеведения 
                        в целом уже при своей жизни увидели добрые плоды от трудов рук своих.</div>
                        
                        
                        <div class ={"inf-5_podpis"}>Текст доклада приводится по изданию: <div>Краеведение Москвы. 
                        Научно-методические материалы в помощь краеведам / <div>Под ред. Л.В.Ивановой, С.О.Шмидта. М., 1990. С. 49–51.</div></div></div>

                       
                         
                        
                        
                          
                        </table>
                        
                          
                    
                    </div>        
      


             
   

      
      <Link to='/sobor'><div class='BiExit'><BiExit /></div></Link>
    </div>
  );
}

export default Ep;
