import React from 'react'
import flag from "./region/Maslyaninskiy/herb1.png"
import emblem from "./region/Maslyaninskiy/herb2.png"
import admin from "./region/Maslyaninskiy/admin.jpg"
import church_1_image from "./region/Maslyaninskiy/h1.jpg"
import church_2_image from "./region/Maslyaninskiy/h2_.jpg"
import church_3_image from "./region/Maslyaninskiy/h5.jpg"
function Maslyaninskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
            <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="Флаг Баганск" title="Герб" />
                    </div>
                    <h1 className='head'>Маслянинский район </h1>
                </div>
                
                <div class="about-region">
                    
                    {/* о районе */}
                    <div class="inf-4">
                            В 1920 году образована Маслянинская волость, вошедшая в Барнаульский уезд Алтайской губернии.
                            Маслянинский район образован в 1924 году в составе Новосибирского округа Сибирского края.
                             В 1929 году был объединен с Легостаевским районом; власти объединённого района разместились 
                             в селе Никоново, и он стал называться Никоновским районом.
                            В июне 1933 года власти района опять переехали в село Маслянино, и он стал вновь называться 
                            Маслянинским районом.
                            В 1935 году из Маслянинского района был вновь выделен Легостаевский район. 
                            <div> В 1937 район был 
                            включен в состав новообразованной Новосибирской области.
                            23 декабря 1954 года Легостаевский район был упразднён, и часть его земель вошла в состав
                             Маслянинского района. Административный центр — посёлок городского типа Маслянино.   
                            Население 23 485 чел. (2021 г.)</div>
                            
                            
                        
                    </div>
                    
                </div>
                <div class="churches">
                <div class="churches title">
                        <h2 className='churches-title'>Церкви Маслянинского района</h2>
                    </div>

                    {/* Храм во имя Святителя Николая */}
                    <table class='table-w'>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Храм во имя Святителя Николая, с. Маслянино</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={450} alt="Храм во имя Святителя Николая (с. Маслянино)" />
                        </div>
                        <div class='inf-4'>
                        Свято-Никольский храм в селе Маслянино был освящен 13 декабря 1883 года. 
                        По преданию храму подарил икону Николая Угодника последний русский император Николай II, 
                        когда, еще будучи цесаревичем, останавливался в здешних местах на пути в Индию.
                        <div>В конце 20-х гг. XX в. храм, как и другие культовые учреждения, закрыли. 
                             В разные годы в здании было зернохранилище,
                             клуб, дом пионеров. В 1990 г. храм был восстановлен и вновь освящен в честь Святителя Николая.</div>
                             <p><b>Адрес:</b> р.п. Маслянино, ул. Школьная, 1.</p>
                        </div>
                        
                    </div>
                    </table>


                    {/* Церковь во имя Матроны Московской */}
                    <table class='table-w'>
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>Церковь во имя Матроны Московской, с. Пайвино</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={400} alt="Церковь во имя Матроны Московской, с. Пайвино" title="Церковь во имя Матроны Московской" />
                        </div>
                        <div class='inf-4'>
                        Строительство церкви Матроны Московской в селе Пайвино Маслянинского района началось в 2009 году. 
                        Небольшая деревянная церковь представляет собой четверик, завершенный небольшим шатром, 
                        увенчанным луковичной главкой на глухом барабане, с алтарем и притвором.  
                        <div>В 2014 году церковь была освящена.</div>
                        <p><b>Адрес:</b> с. Пайвино, ул. Садовая, 22/1.</p>

                        </div>
                    </div>
                    </table>


                    {/* равноапостольного великого князя Владимира */}
                    <table class='table-w'>
                    <div class="church-2">
                        <div class="church-2-title">
                            <p>Церковь во имя святого равноапостольного великого князя Владимира, с. Серебренниково</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_3_image} width={650} alt="Церковь святого равноапостольного князя Владимира" 
                            title="Церковь во имя святого равноапостольного князя Владимира"/>
                        </div>
                        <div class='inf-4'>
                        Церковь во имя святого равноапостольного князя Владимира была построена в 2015 году. 
                        В сентябре 2016 года была совершена первая Божественная Литургия в храме.    
                        
                        <p><b>Адрес:</b> с. Серебренниково, ул. Центральная, 12А.</p>

                        </div>
                    </div>
                    </table>
                  
                </div>


                {/* <div class="info-region">
                    <div class="attractions">
                        <div class="attractions-title">
                            <h2>Достопримечательности:</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Маслянинская центральная модельная библиотека.</p>
                            </div>
                            <div class="attraction-2-text">
                                <p>Историко-краеведческие музей.</p>
                            </div>
                            <div class="attraction-3-text">
                                <p>Дом музей "Усадьба строителя".</p>
                            </div>
                            <div class="attraction-4-text">
                                <p>Дом купца Дранишникова.</p>
                            </div>
                            <div class="attraction-5-text">
                                <p>Дом-музей “Театр бирюкова”.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
               
                
            </section>
        </div>
    );
}

export default Maslyaninskiy