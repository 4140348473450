import React from 'react'
import flag from "./region/Kolivanskiy/herb1.png"
import emblem from "./region/Kolivanskiy/he1.png"
import attraction_1_image from "./region/Kolivanskiy/priroda1.png"
import attraction_2_image from "./region/Kolivanskiy/priroda2.png"
import attraction_3_image from "./region/Kolivanskiy/priroda3.png"
import church_1_image from "./region/Kolivanskiy/5.jpg"
import church_2_image from "./region/Kolivanskiy/h2.png"
import church_2_1_image from "./region/Kolivanskiy/skala.jpg"
import monastery_1_image from "./region/Kolivanskiy/m1.png"
import monastery_2_image from "./region/Kolivanskiy/m2.jpg"
import monastery_3_image from "./region/Kolivanskiy/m3.png"
import o1 from "./region/Kolivanskiy/o1.jpg"


function Kolivanskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
               
               <table>
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={200} />
                    </div>
                    <h1 className='head'>Колыванский район</h1>
                </div>
                <div class="about-region">
                    
                        <div class="inf-4">
                    <p>
                        <p>Население 23 449 чел. (2021 г.). Колыванский район был образован в 1925 году в составе Новосибирского округа Сибирского края. 
                        С 1930 года в составе Западно-Сибирского края. В 1937 году район был включен в состав Новосибирской области.</p>
                        <p>В 1955 году в состав района вошла территория бывшего Пихтовского.</p> 
                            
                        <p>В 1990 году Колывань получила статус исторического поселения, чему способствовали и ее двухвековая история, 
                        и богатое архитектурное наследие.</p>
                       <p>Административный центр – посёлок городского типа Колывань.</p>
                    </p>
                    </div>
                </div>
                </table>
                              
                        
                        <div class="center-image">
                            <img src={attraction_1_image} width={600} alt=" посёлок городского типа Колывань."
                                title="Колывань" />
                        </div>
                    
                
                
                
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div>
                        <h2 className='churches-title'>Храмы Колыванского района</h2>
                    </div>
                   
                   

                    <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            Монастырский собор во имя Александра Невского 
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_image} width={370}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        
                        Решение о возведении церкви было принято 29 октября 1878 года на общественном сходе жителей Колывани. 
                        Первоначально предполагалось, что церковь будет носить имя Покрова Пресвятой Богородицы. 
                        Однако после убийства 1 марта 1881 года императора Александра II колыванский купец II гильдии 
                        Кирилл Климович Кривцов
                        пожелал на свои средства построить в память убиенного Александра II
                        церковь имени Александра Невского по плану, утвержденному для церкви Покрова и 
                        получил разрешение императора Александра III. Строительство было завершено в 1887 году.
                        

                        <p>
                         В советское время, несмотря на массированную антирелигиозную пропаганду, службы в церкви продолжались до начала 1960-х годов.
                         Во время Хрущёвских гонений, в 1962 году, церковь была закрыта, а в 1968 году, по решению руководителей райкома и райисполкома, 
                         – снесены купола. 
                         Позднее были утрачены дверные полотна и рамы оконных проемов, пол, деревянные хоры, лестницы на хоры и колокольню, 
                         убранство интерьера и ограда церкви.
                         </p>
                         <p>   В 1990 году храм вернули Церкви.  А в 1991 году в Колывани был устроен Покровский Александро-Невский
                            женский монастырь. Первой настоятельницей монастыря была назначена игумения Надежда (Ерёмина). 
                            Сразу после возвращения храма верующим в нём начались восстановительные работы.     
                            16 мая 1991
                        года, во время своего первого визита в Сибирь, Патриарх Московский
                        и всея Руси Алексий II благословил новый монастырь. А через год,
                        19 июля 1992 года, после завершения восстановительных работ, митрополит Тихон совершил торжественное освящение храма во имя святого
                        благоверного великого князя Александра Невского. </p>
                        <p>Архитектура храма отличается простотой и цельностью композиции, стройностью силуэта, хорошими пропорциями объемов и деталей.  
                        Двуглавая церковь принадлежит к типу традиционных трапезных церквей с трехчастным членением плана (тип церкви «кораблем»), 
                        когда на одной композиционной оси восток–запад находятся основной объем, трапезная и колокольня. 
                        
                        
                        </p>

                        <p><b>Адрес:</b> р.п. Колывань, ул. Калинина, 22.</p>  </div> 
                    </div>
                    </table>

                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Собор Святой Живоначальной Троицы (1867 г.)
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} width={600}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        <p>Первоначально собор был построен однопрестольным. Приделы левый во имя святой 
                            великомученицы Екатерины 
                        и правый во имя преподобного Кирилла и мученицы Наталии сооружены позднее 
                        и освящены в 1876 году 20 марта и 28 октября. 
                        </p>
                        <p>
                        Первым настоятелем был протоиерей Михаил Федорович Вавилов. В 1889 году при церкви имелась 
                        библиотека, которой пользовался причт
                        и некоторые из прихожан. В 1902 году при соборе была открыта церковно-приходская школа.
                        </p>
                        <p>
                        В годы советской власти здание собора подверглось значительному разрушению и реконструкции: 
                        в 1930-е годы утрачены колокольня, купола и главки, в 1950-е годы главный западный фасад получил 
                        завершение треугольным фронтоном, декорированным советской символикой. Вместе с тем, масштаб 
                        здания, фрагменты декора его боковых фасадов и конфигурация плана сохранили характерные признаки 
                        культовой архитектуры.
                        В конце 2009 года при соборе был открыт приход и начались реставрационные работы.
                        В ноябре 2011 года был отслужен первый молебен. 15 февраля 2013 состоялось освящение собора, 
                        но реставрационные работы продолжались. </p>
                       <p> 22 сентября 20224 года митрополит Новосибирский и Бердский Никодим совершил Великое
                         освящение восстановленного собора во имя Святой Живоначальной Троицы. 

                        </p>
                        <p><b>Адрес:</b> р. п. Колывань, ул. Советская, 34.</p>
                        </div> 
                    </div>
                    </table>


                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            Церковь Святителя Николая Чудотворца, с. Скала
                        </div>
                        <div class="church-1-image">
                            <img src={church_2_1_image} width={600}/>
                        </div>
                        
                        <div class ={"inf-4"}>
                        <p>Храм был освящен 11 сентября 2022 года в день празднования 300-летия со дня основания села Скала.
                            Освящение совершил митрополит Новосибирский и Бердский Никодим. 
                            Строительство храма велось 3 года, и сейчас храм занимает центральное место этого старинного села.  
                        </p>
                       
                        <p><b>Адрес:</b> с. Скала, ул. Молодежная 17А</p>
                        </div> 
                    </div>
                    </table>

                </div>





                <div class="monasteries">
                        <div class="monasteries-title">
                            <h2 className='churches-title'>Монастыри Колыванского района</h2>
                        </div>
                        <div class="monastery-1">


                            <div class="church-1-title">
                            Покровский Александро-Невский женский монастырь, р. п. Колывань
                            </div>
                            

                            <table>
                            <div class="Monastery-1-image">
                                
                                <table>
                                <div class="church-1-image">
                                    <img src={monastery_1_image} width={500}/>
                                </div>

                                <div class ={"inf-4"}>
                                
                                Покровский Александро-Невский женский монастырь создан трудами и заботами епископа Новосибирского и Барнаульского Тихона.
                                 Епископ Тихон, прибыв на Новосибирскую кафедру в 1990 году, проявил заботу об устройстве монастыря для монашествующих
                                  при Вознесенском кафедральном соборе. После долгих поисков выбор пал на Колывань, бывший купеческий город, основанный в 1797 году 
                                  и игравший в XIX веке значительную роль в Сибирском крае.   

                                  <p>В 1991 году, во время своего первого визита в Сибирь, в Новосибирскую епархию прибыл Святейший Патриарх Московский и всея Руси Алексий II.
                                  На праздник Вознесения Господня, 16 мая, Святейший Патриарх посетил Колывань и освятил закладной камень в
                                   основание жилого корпуса вновь создаваемой женской обители, а также большой деревянный крест, ныне расположенный 
                                   в притворе храма во имя благоверного князя Александра Невского.</p>

                                   
                                </div> 
                                </table>

                                <table>
                                <div class="church-1-image">
                                    <img src={monastery_2_image} width={500}/>
                                </div>

                                <div class ={"inf-4"}>
                                <p>В настоящее время в Покровском Александро-Невском монастыре трудятся 35 сестер. 
                                   Они несут разнообразные послушания: в храме, на клиросе, на колокольне, в иконописной мастерской, в ризнице,
                                    в просфорне, трапезной, котельной, подсобном хозяйстве.</p>
                                    
                                     <p>Сестры овладели многими строительными специальностями 
                                    - каменщика, штукатура, маляра; окончили курсы профессиональных водителей. 
                                    Теперь они на грузовых машинах возят картошку и сено с монастырского поля, песок, глину, пиломатериал для стройки.</p>
                                     
                                     <p>Очень много в монастыре так называемых рукодельных послушаний - сестры пишут и вышивают иконы, шьют облачения, 
                                     вырезают из дерева и бересты киоты для икон. Насельницы монастыря окормляют часовню во имя святого
                                      Лазаря Четверодневного, открытую при районной больнице. Они также заботятся о престарелых и больных прихожанах 
                                      храма, проводят занятия Воскресной школы с детьми-сиротами из интерната. От урожая, собранного со своих огородов, 
                                      монастырь отправляет помощь приходам и нескольким детским учреждениям.</p>


                                        
                                </div> 
                                </table>


                                <div class="church-1-image">
                                    <img src={monastery_3_image} width={500}/>
                                </div>

                                <div class ={"inf-4"}>
                                <p>В 2004 году в обители была воздвигнута и освящена водосвятная часовня в честь царственных страстотерпцев, Новомучеников и исповедников Российских.
                                      В монастыре три престольных праздника: первый - 1 (14) октября - Покров Пресвятой Богородицы, второй - 23 ноября (6 декабря) - день памяти святого благоверного князя Александра Невского, в схиме Алексия, 
                                      и третий - 30 августа (12 сентября) - день памяти перенесения мощей благоверного князя.</p>

                                    <p>Чтимые иконы и святыни храма: икона святого благоверного князя Александра Невского в схиме с частицей мощей; 
                                        икона Пресвятой Богородицы — Игумении святой горы Афонской; икона святителей Иннокентия Иркутского, Тихона, 
                                        Патриарха Московского и Луки Симферопольского с частицей мощей; икона преподобных Силуана и Гавриила Афонских 
                                        с частицами мощей; икона преподобной Александры первоначальницы Дивеевской с 
                                        частицей мощей; икона блаженного Иоанна Тульского с частицей мощей; ковчежец с мощами многих святых</p>  
                                        <p><b>Адрес:</b> р.п. Колывань, ул. Калинина, 22.</p>
                                </div>

                                
                            </div>
                            </table>
                        </div>
                    </div>






                    



                    <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Памятники природы:</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Минзелинское озеро</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={o1} width={700} title="Озеро" />
                            </div>
                        </div>
                    </div>


                    <div class="attractions">
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Достопримечательности Колыванского района:</h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Памятник Николаю Чудотворцу</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={attraction_2_image} width={400} alt="Памятник Николаю Чудотворцу" title="Памятник" />
                            </div>


                        </div>

                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>Колыванский краеведческий музей</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={attraction_3_image} width={600}/>
                            </div>

                            
                        </div>



                    </div>

                    
                    
                    
                    








            </section>
        </div>);
}

export default Kolivanskiy


/*
function Kolivanskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region">
                    <h1>Колыванский район</h1>
                </div>
                <div class="about-region">
                    <div class="flag">
                        <img src={flag} alt="Флаг Колыванского района" title="Герб" />
                    </div>
                    <div class="emblem">
                        <img src={emblem} alt="Герб Колыванского района" title="Герб" />
                    </div>
                    <div class="text">
                        <p>Население 23 449 чел. (2021 г.). Колыванский район был образован в 1925 году в составе Новосибирского округа Сибирского края. С 1930 года в составе Западно-Сибирского края. В 1937 году район был включен в состав Новосибирской области. В 1955 году в состав района вошла территория бывшего Пихтовского. В 1990 году Колывань получила статус исторического поселения, чему способствовали и ее двухвековая история, и богатое архитектурное наследие.</p>
                    </div>
                </div>
                <div class="info-region">
                    <div class="administrative-center">
                        <div class="adminisrative-center-title">
                            <h2>Административный центр – посёлок городского типа Колывань.</h2>
                        </div>
                        <div class="administrative-center-image">
                            <img src={attraction_1_image} alt=" посёлок городского типа Колывань."
                                title="Колывань" />
                        </div>
                    </div>
                    
                    <div class="attraction">
                        <div class="attraction-title">
                            <h2>Памятники природы:</h2>
                        </div>
                        <div class="attraction-1-text">
                            <p>Территория района богата озерами. Наиболее крупное из них – Минзелинское, которое называют «жемчужиной» Колыванского района.</p>
                        </div>
                        <div class="attraction-title-2">
                            <h2>Достопримечательности Колыванского района:</h2>
                        </div>
                        <div class="attraction-2">
                            <div class="attraction-2-text">
                                <p>Памятник Николаю Чудотворцу.</p>
                            </div>
                            <div class="attraction-2-image">
                                <img src={attraction_2_image} alt="Памятник Николаю Чудотворцу" title="Памятник" />
                            </div>
                        </div>
                        <div class="attraction-3">
                            <div class="attraction-3-text">
                                <p>В районе сохранилось здание церковно-приходской школы(сейчас в этом здании находится Колыванский краеведческий музей).</p>
                            </div>
                            <div class="attraction-3-image">
                                <img src={attraction_3_image} alt="здание церковно-приходской школы" title=" здание церковно-приходской школы" />
                            </div>
                        </div>
                    </div>
                </div>
                
                
                <div class="churches">
                    <div class="churches-title">
                        <h2>Храмы Колыванского района:</h2>
                    </div>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Собор Святой Живоначальной Троицы (1867 г.)</p>
                        </div>
                        <div class="church-1-image">
                            <img src={church_1_image} alt="Собор Святой Живоначальной Троицы" title="Храм1" />
                        </div>
                        <div class="church-2">
                            <div class="church-2-title">
                                <p>Монастырский собор во имя Александра Невского (1887 г.)</p>
                            </div>
                            <div class="church-2-image">
                                <img src={church_2_image} alt="Монастырский собор во имя Александра Невского"
                                    title="Храм2" />
                                <img src={church_2_1_image} alt="Внутряночка собора" title="Внутряночка" />
                            </div>
                        </div>
                    </div>
                    <div class="monasteries">
                        <div class="monasteries-title">
                            <h2>Монастыри Колыванского района:</h2>
                        </div>
                        <div class="monastery-1">
                            <div class="monastery-1-title">
                                <p>Александро-Невский Покровский монастырью.</p>
                            </div>
                            <div class="Monastery-1-image">
                                <img src={monastery_1_image} alt="Александро-Невский Покровский монастырь"
                                    title="Монастырь1-1" />
                                <img src={monastery_2_image} alt="Александро-Невский Покровский монастырь"
                                    title="Монастырь1-2" />
                                <img src={monastery_3_image} alt="Александро-Невский Покровский монастырь"
                                    title="Монастырь1-3" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Kolivanskiy
*/