import React from 'react'
import emblem from "./region/Zdvinskiy/herb2.png"
import church_1_image from "./region/Zdvinskiy/h2.jpg"
import church_2_image from "./region/Zdvinskiy/h3.jpg"
import ryam from "./region/Zdvinskiy/ryam.jpg"
import ostrov from "./region/Zdvinskiy/ostrov.jfif"
import za from "./region/Zdvinskiy/za.jpg"
function Zdvinskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
            <div class="title-region"> <div class="flag">
                        <img src={emblem} alt="Герб" title="" />
                    </div>
                    <h1 className='head'>Здвинский район</h1>
                </div>
           
            
                <div class="about-region">

                    
                    <div class="inf-4">
                        <p>Население — 13 542 человек (2021 г). Район образован в 1925 году как Нижне-Каргатский 
                            в составе Барабинского
                            округа Сибирского края, с 1930-го в составе Западно-Сибирского края. 
                            В 1933 году район переименован
                            в Здвинский.</p>
                    </div>
                </div>
                <div class="churches">
                   
                   
                    <div class="church-1">
                    <div class="churches title">
                        <h2 className='churches-title'>Храмы Здвинского района</h2>
                    </div>
                        

                        <table>
                        <div class="church-1-title">
                           Храм в честь апостола и евангелиста Иоанна Богослова, с. Здвинск
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_1_image} width={500} alt="Церковь Иоанна Богослова" title="Храм1" />
                        </div>
                        <div class ={"inf-4"}>
                        Приход церкви во имя Cвятого апостола Иоанна Богослова в селе Здвинске 
                        Новосибирской области Карасукской Епархии Русской Православной Церкви 
                        был образован 2 марта 1991 года. В 2007 году была освящена новопостроенная церковь.
                         <p><b>Адрес:</b> с. Здвинск, ул. Калинина, 51а.</p> 
                         </div>
                        </table>
                
                
                <p></p>

                        <table>
                        <div class="church-1-title">
                        Храм в честь Покрова Пресвятой Богородицы, с. Верх-Каргат
                        </div>
                        
                        <div class="church-1-image">
                            <img src={church_2_image} width={500} alt="Покрова Пресвятой Богородицы"
                             title="Церковь Покрова Пресвятой Богородицы" />
                        </div>
                        <div class ={"inf-4"}>
                        Покровская церковь в селе Верх-Каргат была построена и освящена в 1902 году, 
                        о чем есть свидетельство в Томских епархиальных ведомостях (№ 10, 1903). 
                        Деревянная однопрестольная церковь была построена на средства 
                        Преосвященнейшего Макария (Невского), епископа Томского и Барнаульского 
                        (до 1891 года бывшего начальником Алтайской духовной миссии), 
                        пожертвовавшего две тысячи рублей, и пожертвования мирян. 
                        Храм был освящен, по устным сведениям, архимандритом Иннокентием (Соколовым), 
                        помощником начальника Алтайской 
                        духовной миссии 9 марта 1902 года.
                        <div>После революции в 1939 году храм был закрыт. 
                            В нем располагался клуб, а позднее в 1970-е годы — разобран.</div>
                        <div>Восставновление святыни началось в 2012 году. Были
                            освящены камень и место под  строительство храма.</div>  
                        <div>В июне 2017 года вновь построенный храм был освящен и открыт для всех жителей района.
                             </div>
                         <p><b>Адрес:</b> с. Верх-Каргат, ул. Черниговская, 2А.</p> 
                         </div>
                        </table>


                
                
                    </div>
                </div>
                <div class="info-region">
                    <div class="attractions">
                        <div class="attraction-title">
                            <h2>Достопримечательности Здвинского района</h2>
                        </div>
                        <div class="attraction-1">
                                                        
                            <div class="attraction-2-text">
                                <p>Остров Голинский</p>
                            </div>
                            <div class="center-image">
                                <img src={ostrov}  width={900} alt="Остров Голинский" title="Остров" />
                            </div>
                            <div class="attraction-3-text">
                                <p>Михайловский рям</p>
                            </div>
                            <div class="center-image">
                                <img src={ryam}  width={900} alt="Михайловский рям" title="рям" />
                            </div>
                            <div class="attraction-4-text">
                                <p>Баганское займище</p>
                            </div>
                            <div class="center-image">
                                <img src={za}  width={900} alt="Баганское займище" title="Баганское займище" />
                            </div>
                  
                        </div> 
                    </div>
                </div>
                
            </section>
        </div>);
}
export default Zdvinskiy