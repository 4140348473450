import React from 'react'
import attraction_1_image from "./region/Vengerovskiy/priroda1.png"
import flag from "./region/Vengerovskiy/gerb1.png"
import emblem from "./region/Vengerovskiy/gerb2.png"
import church_1_image from "./region/Vengerovskiy/h2.jpg"
import church_2_image from "./region/Vengerovskiy/h3.jpg"
import tus from "./region/Vengerovskiy/tus.jpg"
function Vengerovskiy() {
    return (<div class="main">
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
        <meta name="viewport" content="user-scalable=yes"/>
        <title>Document</title>
        <section class="region">
        <div class="title-region"> <div class="flag">
                        <img src={emblem} width={180} alt="Герб" title="Герб" />
                    </div>
                    <h1 className='head'>Венгеровский район</h1>
                </div>
              
            <div class="about-region">
                               
                <div class="inf-4">
                    <p>Район образован в 1925 году под наименованием Спасский в составе Барабинского округа 
                        Сибирского края, с 1930 года — в составе Западно-Сибирского края. В 1933 году райцентр село 
                        Спасское было переименовано в Венгерово, а Спасский район — в Венгеровский. 
                        В 1937 году район был включён во вновь образованную Новосибирскую область.</p>
                        <p>Район расположен на западе Новосибирской области, 
                            в северо-западной части Барабинской низменности. Граничит с
                             Кыштовским, Северным, Куйбышевским, Чановским, Татарским, 
                             Усть-Таркским районами Новосибирской области, а также с Омской областью.
                              Территория района по данным на 2008 год — 631,3 тыс.га,
                             в том числе сельхозугодья — 557,3 тыс. га.</p>
                    </div>
            </div>

            <div class="churches">

                <div class="church-1">
                    <div>
                        <h2 className='churches-title'>Храмы Венгеровского района</h2>
                    </div>
                    <div class="church-1-title">
                        Храмовый комплекс в честь Нерукотворного Образа Господа Иисуса Христа, с. Венгерово
                    </div>
                    <table>
                    <div class="church-1-image">
                        <img src={church_1_image} width={680} alt="Храм с. Венгерово" title="храм с. Венгерово" />
                        &#160;
                        <img src={church_2_image} width={600} alt="Храм с. Венгерово" title="храм с. Венгерово" />
                    </div>
                    </table>
                    <div class="inf-4">
                        Храм Спаса Нерукотворного — кирпичный сельский храм, сооружённый в 1859 
                            году. В годы гонений был закрыт. 
                            <div>Возвращён верующим в 1992 году, восстановлен с частичной реконструкцией.
                             В настоящее время представляет собой прямоугольную в плане пятиглавую постройку с 
                             отдельно стоящей шатровой колокольней в стиле архитектуры XVII в.  </div>
                             <p><b>Адрес:</b> с. Венгерово, ул. Ленина, 81.</p> 
                    </div>
                </div>

            </div>


            <div class="info-region">
                <div class="attractions">
                   
                    <div class="attration-1">
                        <div class="attraction-1-text">
                            <p></p>
                        </div>
                                                                 
                        <div class="attractions-title">
                            <h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        
                        
                        <div class="attraction-1-text">
                            озеро Тюсьмень
                        </div>
                        <div class="attraction-1-image">
                            <img src={tus}  width={800} alt="Озеро тюсьмень" title="озеро" />
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </section>
    </div>
    );
}
export default Vengerovskiy