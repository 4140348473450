import React from'react'
import flag from "./region/Iskitimskiy/herb1.png"
import emblem from "./region/Iskitimskiy/herb2.png"
import map from "./region/Iskitimskiy/map.png"
import church_1_image from "./region/Iskitimskiy/h1.png"
import church_2_image from "./region/Iskitimskiy/h2.png"
import church_3_image from "./region/Iskitimskiy/h3.png"
import church_4_image from "./region/Iskitimskiy/h4.png"
import church_5_image from "./region/Iskitimskiy/hh5.jpg"
import church_6_image from "./region/Iskitimskiy/burm2.jpg"
import church_7_image from "./region/Iskitimskiy/pokrova.jpg"
import church_8_image from "./region/Iskitimskiy/h8.png"
import church_9_image from "./region/Iskitimskiy/zavjalovo.jpg"
import admin from "./region/Iskitimskiy/admin.jpg"
import aa1 from "./region/Iskitimskiy/aa1.png"
import aa2 from "./region/Iskitimskiy/aa2.png"
import aa3 from "./region/Iskitimskiy/aa3.jpg"
function Iskitimskiy() {
    return (
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
                <meta name="viewport" content="user-scalable=yes"/>
                <title>Document</title>
            </div>
            <section class="region">
                <div class="title-region"> <div class="flag">
                        <img src={emblem} width={200} alt="Герб" title="Герб" />
                    </div>
                    <h1 className='head'>Искитимский район</h1>
                </div>
                
                <div class="about-region">
                    
                        <div class="inf-4"><p></p>
                        <p>
                        Район образован 10 января 1935 года в составе Западно-Сибирского края. 
                        28 сентября 1937 года район был включён во вновь образованную Новосибирскую область. 
                        В 1965 году в состав района вошла территория бывшего Легостаевского района.
                        <div>Административный центр — город Искитим. Население района — 59 тыс. человек. </div>
                        </p>
                    </div>
                    <div class='center-image'><img src={admin} width={700}/></div>
                </div>
                <div class="info-region">
                    
                </div>
                <div class="churches">
                    <div class="churches title">
                        <h2 className='churches-title'>Храмы Искитимского района</h2>
                    </div>
                   


                    {/* Кафедральный собор во имя Святителя Николая, г. Искитим */}
                    <table>
                    <div class="church-1">
                        <div class="church-1-title">
                            <p>Кафедральный собор во имя Святителя Николая, г. Искитим</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_1_image} width={520} alt="Кафедральный собор во имя Святителя Николая Церковь"
                        title="Храм1" />
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    Первый храм в г. Искитиме размещался на его окраине в селе Койново. Он находился на берегу реки Койниха и был освящен во имя Святителя Николая. 
                    Дата постройки неизвестна. В 1920-1930 годах храм был закрыт. Настоятель арестован, приход ликвидирован. 
                    Со временем река, близ которой был построен храм, стала подмывать фундамент храма, и здание разобрали на хозяйственные нужды. 
                    Это было примерно в 1946 году. 
                    <div>В 1970-х годах верующие жители Искитима собирались в разных домах для общей молитвы.
                         Впоследствии был куплен дом, в котором собрания стали постоянными.  
                         По просьбе верующих из Новосибирска приезжали священнослужители для совершения богослужений.  
                         В 1989 году решением исполнительного комитета искитимского городского Совета народных депутатов 
                         было разрешено строительство нового здания храма.
                             К концу 1990 года рядом с фундаментом будущего храма было построено помещение, 
                             где временно располагалась церковь, в которой совершали богослужения.
                             К 1995 году были выложены стены основного храма, но строительство было приостановлено.
                        </div>

                        <div>В июле 2001 года недостроенное здание храма было передано в ведение мужского монастыря в честь
                             Покрова Пресвятой Богородицы, находящегося в селе Завьялово. 
                            В этом же году монастырь приступил к отделке нижнего храма. 
                            По благословению Высокопреосвященного Тихона, архиепископа Новосибирского и Бердского, 
                            храм был освящен во имя Святителя Николая малым чином. 
                            Первое богослужение в новом здании было совершено на Пасху в 2002 году. </div>
                            <div>11 августа 2017 года состоялось Великое освящение собора. 
                            С 2011 года храм стал кафедральным собором новообразованной Икитимской епархии.</div>


                    <p><b>Адрес:</b>  г. Искитим, ул. Коллективная, 1.</p>  
                    </div> 
                    </table>
                   

                    {/* Владимирской иконы Пресвятой Богородицы */}
                    <table>
                    <div class="church-2">
                        <div class="church-1-title">
                            <p>Церковь в честь Владимирской иконы Пресвятой Богородицы, г. Искитим</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_2_image} width={400} alt="Кафедральный собор во имя Святителя Николая Церковь"
                         />
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    По инициативе городской администрации его возведение началось в 2001 г. и осуществлялось на средства 
                    прихожан, предприятий и организаций Искитима,
                     с энтузиазмом откликнувшихся на призыв о помощи в этом благом деле. Кто-то вносил деньги, 
                     кто-то жертвовал материалы. А те, у кого не было ни того, ни другого, помогали техникой и рабочей силой. 
                    Спроектировала храм областная проектная организация совместно с Новосибирской епархией и 
                    Новосибирским государственным архитектурно-строительным университетом, которые учли все 
                    особенности постройки церкви.
                    <p>20 ноября 2003 г. в новом храме прошло первое богослужение. 
                        Официальное открытие и освящение церкви состоялось 4 января 2004 г. 
                        22 мая 2006 г. произошло еще одно знаменательное событие, связанное с храмом
                         – глава города В.Г. Пфейфер 
                        и архиепископ Новосибирский и Бердский Тихон подписали договор дарения храма в честь иконы 
                        Божьей Матери Владимирской Новосибирской епархии.
                        </p>
                        
                        
                    <p><b>Адрес:</b> г. Искитим, ул. Пушкина, 49.</p>  
                    </div> 
                    </table>


                    {/* иконы Божией Матери “Неупиваемая чаша” */}
                    <table>
                    <div class="church-3">
                        <div class="church-1-title">
                            <p>Церковь в честь иконы Божией Матери “Неупиваемая чаша”, г. Искитим</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_3_image} width={400} alt="Церковь в честь иконы Божией Матери “Неупиваемая чаша”"  />
                        
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    Храм в честь иконы Пресвятой Богородицы «Неупиваемая чаша» вырос в Индустриальном микрорайоне в рекордные сроки – с начала строительства прошло шесть месяцев. Первый символический камень в основание церкви был заложен и освящен 18 мая.
                     Именно в этот день по православному календарю отмечают праздник в честь иконы «Неупиваемая чаша».
                        Монтаж конструкций креста и купола начался осенью, причем сборка проходила непосредственно на площадке перед храмом. 
                         
                    <p></p>
                    <p>Работа заняла три месяца. Диаметр купола составил 5 метров, высота – 8 метров, высота креста 2,7 метра. 
                        Выполнены они из нержавеющей стали с титановым покрытием, которое и создает эффект «золотого» купола.
                        Вся работа выполнена вручную двумя мастерами из города Бердска - Виктором Григоренко и Николаем Непомнящих.</p>
                        
                    <p><b>Адрес:</b> г. Искитим, микрорайон Индустриальный, Набережная, 24.</p>  
                    </div> 
                    </table>
                    
                    

                    {/* церковь в честь иконы Божией Матери "Живоносный Источник */}
                    <table>
                    <div class="church-4">
                        <div class="church-1-title">
                            <p>Церковь в честь иконы Божией Матери "Живоносный Источник", микрорайон Ложок</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_4_image} width={500} alt="Церковь в честь иконы Божией Матери “Живоносный Источник”"  />
                        
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    В 1999 году православные жители микрорайона Ложок г. Искитима Новосибирской области 
                    обратились в Новосибирскую епархию за благословением на создание прихода. 
                    Новообразованная община окормлялась монастырем в честь Новомучеников и Исповедников Российских 
                    г. Новосибирска.
                        <div> В апреле 2002 г. территориальная администрация г. Искитима передала в безвозмездное пользование здание 
                            для обустройства храма для проведения богослужений. Также здесь была устроена воскресная 
                            школа и библиотека.
                        </div>
                    <p><b>Адрес:</b> г. Иcкитим, микрорайон Ложок, ул. Центральная, 12.</p>  
                    </div> 
                    </table>


                    {/* Церковь в честь Новомучеников и Исповедников Церкви Русской */}
                    <table>
                    <div class="church-5">
                        <div class="church-1-title">
                            <p>Церковь в честь Новомучеников и Исповедников Церкви Русской, микрорайон Ложок</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_5_image} width={500} alt="Церковь в честь Новомучеников и Исповедников Церкви Русской" />
                        
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    13 августа 2006 г. Архиепископ Тихон совершил освящение закладного камня в основание храма в честь 
                    Новомучеников и исповедников Церкви Русской на Святом источнике в Ложке.
                     Храм построен по проекту Петра Александровича Чернобровцева, известного новосибирского архитектора.
                    <p>4 октября 2015 г. на Святом источнике в Ложке освящён храм в честь Новомучеников и исповедников Церкви Русской.</p>
                        
                    <p><b>Адрес:</b> г. Искитим, микрорайон Ложок.</p>  
                    </div> 
                    </table>


                    {/* Церковь в честь Покрова Пресвятой Богородицы, Линево */}
                    <table>
                    <div class="church-5">
                        <div class="church-1-title">
                            <p>Церковь в честь Покрова Пресвятой Богородицы, р.п. Линёво</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_7_image} width={500} alt="Церковь в честь Покрова Пресвятой Богородицы, р.п. Линёво" />
                        
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    В 1992 году группа верующих посёлка Линёво создала православную общину, в 1993 году был зарегистрирован приход.
                    Администрация посёлка выделила помещение по улице Первопроходцев 1 для обустройства в нём церкви, 
                    раньше здесь размещался продуктовый склад. Силами прихожан и переоборудовали выделенное помещение под храм.
                    <div>Строительство новой церкви — в честь Покрова Пресвятой Богородицы 
                        было начато в 2003 году и завершено в 2011 году. 15 октября 2011 года, Высокопреосвященнейший Тихон, 
                        Архиепископ Новосибирский и Бердский, в сослужении клириков Новосибирской епархии, 
                        совершил чин освящения нового храма.</div>

                    
                        
                    <p><b>Адрес:</b> р.п. Линёво, Коммунистический проспект, 21.</p>  
                    </div> 
                    </table>


                    {/* Церковь в честь Равноапостольного князя Владимира */}
                    <table>
                    <div class="church-5">
                        <div class="church-1-title">
                            <p>Церковь в честь святого равноапостольного князя Владимира, с. Бурмистрово</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_6_image} width={500} 
                        alt="Церковь в честь святого равноапостольного князя Владимира, с. Бурмистрово" />
                        
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    Храм во имя святого равноапостольного великого князя Владимира, нижний предел храма освящен в честь святаго благоверного 
                    великого князя Александра Невского. Подворье епархиального мужского монастыря 
                    села Завьялово в честь Покрова Пресвятой Богородицы Искитимской епархии.
                    <div>Храм построен в 2003 году.</div>
                        
                    <p><b>Адрес:</b> с. Бурмистрово.</p>  
                    </div> 
                    </table>



                    {/* церковь Сошествия Святого Духа на апостолов, ст. Евсино */}
                     <table>
                    <div class="church-8">
                        <div class="church-1-title">
                            <p>Церковь Сошествия Святого Духа на апостолов, ст. Евсино</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_8_image} width={400} alt="Церковь во имя Святого Духа"/>
                        
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    В 2005 году администрацией сельского совета был выделен земельный участок в центре села под строительство православного храма на станции Евсино. 
                    Осенью 2006 года залили фундамент будущего храма и начали возведение стен.
                    <p>26 апреля 2007 года, в Неделю жен-мироносиц, были привезены и освящены колокола. 
                    С того дня о начале службы и о важных моментах в Богослужении стал возвещать колокольный звон.</p>
                        <p> Первая служба в новом помещении, переоборудованном под храм, совершилась 23 апреля 2000 года, в Вербное воскресение.
                        В октябре 2009 года в новом храме была отслужена первая Литургия, 
                        с тех пор в нём регулярно совершаются богослужения.
                         1 мая 2010 года по благословению архиепископа Новосибирского и Бердского Тихона было совершено полное освящение храма. 
                         В 2010 году братьями обители был пристроен притвор, над которым стала возвышаться колокольня,
                         а в 2012 году была сделана еще одна пристройка, в которой разместились церковная лавка и трапезная.
                        </p>
                    <p><b>Адрес:</b> Искитимский район, ст. Евсино, ул. Рабочая, 7б. </p>  
                    </div> 
                    </table>


                    {/* Церковь Покрова Пресвятой Богородицы, с. Завьялово */}
                    <table className='table-w'>
                    <div class="church-8">
                        <div class="church-1-title">
                            <p>Церковь в честь Покрова Пресвятой Богородицы, с. Завьялово</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_9_image} width={400} alt="Церковь Покрова Пресвятой Богородицы"/>
                        
                        </div>
                    </div>
                    <div class ={"inf-4"}>Храм в честь Покрова Пресвятой Богородицы был построен еще в XIX веке. 
                    25 августа 1897 года его освящение совершил Преосвяшеннейший Макарий (Невский), епископ Томский и Барнаульский 
                    <div>В 1932 году храм закрыли, пытались взорвать, но он уцелел. 
                        Здание церкви использовали под разные нужды, включая зернохранилище. 
                        С 1987 года здесь находился сельский краеведческий музей. 16 февраля 1987 года решением Новосибирского 
                        Облисполкома здание Покровского храма взято под государственную охрану как памятник истории и культуры
                        регионального значения.</div>
                        <div>В 1993 году храм был передан Новосибирской епархии Русской Православной Церкви и началась его реставрация.
                        17 июля 1997 года к столетию храма решением Священного Синода
                         Русской Православной Церкви при храме был учрежден епархиальный мужской монастырь в честь Покрова Пресвятой Богородицы.
                        </div>
                    <p><b>Адрес:</b> с. Завьялово, ул. Совхозная, 47Б.  </p>  
                    </div> 
                    </table>





                    {/* <table>
                    <div class="church-6">
                        <div class="church-1-title">
                            <p>Церковь в честь св. вмч. Георгия Победоносца.</p>
                        </div>
                        <div class="church-1-image">
                        <img src={church_6_image} width={600} alt="Церковь в честь свмч. Георгия Победоносца" title="Храм6" />
                        
                        </div>
                    </div>
                    <div class ={"inf-4"}>
                    <p>
                        </p>
                    <p>Адрес: Искитимский район, д. Улыбино, ул. Школьная, 11</p>  
                    </div> 
                    </table> */}

                </div>



                


                <p> </p>
                <div class="attractions">
                        
                        <div class="attractions-title">
                            <p> </p><h2 className='head1'>Природные достопримечательности </h2>
                        </div>
                        <div class="attraction-1">
                            <div class="attraction-1-text">
                                <p>«Бердские скалы»</p>
                            </div>
                            <div class="attraction-1-image">
                                <img src={aa1} width={700} alt="«Бердские скалы»" />
                            </div>
                        </div>


                        <div class="attraction-2">
                            <div class="attraction-1-text">
                                <p>Скала «Альпинист»</p>
                            </div>
                            <div class="attraction-2-image">
                                <img src={aa2} width={700} alt="Скала «Альпинист»" />
                            </div>
                        </div>
                    
                        <div class="attraction-3">
                            <div class="attraction-1-text">
                                <p>Беловский водопад</p>
                            </div>
                            <div class="attraction-2-image">
                                <img src={aa3} width={700} alt="Беловский водопад" />
                            </div>
                        </div>
                    
                    </div>
            </section>
        </div>);
}

export default Iskitimskiy








/*

function Iskitimskiy(){
    return(
        <div class='main'>
            <div>
                <meta charset="UTF-8" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Document</title>
            </div>

            <section class="region">

        <div class="title-region">
            <h1>Искитимский район.</h1>
        </div>
        <div class="about-region">
            <div class="flag">
                <img src={flag} alt="Флаг Искитимского района" title="Герб" />
            </div>
            <div class="emblem">
                <img src={emblem} alt="Герб Искитимского района." title="Герб" />
            </div>
            <div class="text">
                <p>Население - 58511. Территория района - 4 383 км². Район образован в 1935 году 10 января.</p>
            </div>
        </div>
        <div class="info-region">
            <div class="map-image">
                <img src={map} width={1200} alt="Карта распоожения Искитимсеого района" title="Карта тупа" />
            </div>
        </div>
        <div class="churches">
            <div class="churches-title">
                <h2>Храмы Искитимского района:</h2>
            </div>
            
            <div class="church-1">
                <div class="church-1-title">
                    <p>Кафедральный собор во имя Святителя Николая Церковь.</p>
                </div>
                <div class="church-1-image">
                    <img src={church_1_image} alt="Кафедральный собор во имя Святителя Николая Церковь"
                        title="Храм1" />
                </div>
            </div>
            <div class="church-2">
                <div class="church-2-title">
                    <p>Церковь в честь Владимирской иконы Пресвятой Богородицы.</p>
                </div>
                <div class="church-2-image">
                    <img src={church_2_image} alt="Церковь в честь Владимирской иконы Пресвятой Богородицы"
                        title="Храм2" />
                </div>
            </div>
            
            <div class="church-3">
                <div class="church-3-title">
                    <p>Церковь в честь иконы Божией Матери “Неупиваемая чаша”.</p>
                </div>
                <div class="church-3-image">
                    <img src={church_3_image} alt="Церковь в честь иконы Божией Матери “Неупиваемая чаша”"
                        title="Храм3" />
                </div>
            </div>
            <div class="church-4">
                <div class="church-4-title">
                    <p>Церковь в честь иконы Божией Матери “Живоносный Источник”.</p>
                </div>
                <div class="church-4-image">
                    <img src={church_4_image} width={900} alt="Церковь в честь иконы Божией Матери “Живоносный Источник”"
                        title="Храм4" />
                </div>
            </div>


            <div class="church-5">
                <div class="church-5-title">
                    <p>Церковь в честь Новомучеников и Исповедников Церкви Русской.</p>
                </div>
                <div class="church-5-image">
                    <img src={church_5_image}
                        alt="Церковь в честь Новомучеников и Исповедников Церкви Русской" title="Храм5" />
                </div>
            </div>

            <div class="church-6">
                <div class="church-6-title">
                    <p>Церковь в честь свмч. Георгия Победоносца.</p>
                </div>
                <div class="church-6-image">
                    <img src={church_6_image} alt="Церковь в честь свмч. Георгия Победоносца"
                        title="Храм6" />
                </div>
            </div>
            <div class="church-7">
                <div class="church-7-title">
                    <p>Церковь в честь Покрова Пресвятой Богородицы.</p>
                </div>
                <div class="church-7-image">
                    <img src={church_7_image} alt="Церковь в честь Покрова Пресвятой Богородицы"
                        title="Храм7" />
                </div>
            </div>
            <div class="church-8">
                <div class="church-8-title">
                    <p>Церковь во имя Святого Духа.</p>
                </div>
                <div class="church-8-image">
                    <img src={church_8_image} alt="Церковь во имя Святого Духа" title="Храм8" />
                </div>
            </div>
            <div class="church-9">
                <div class="church-9-title">
                    <p>Собор во имя Николая Чудотворца.</p>
                </div>
                <div class="church-9-image">
                    <img src={church_9_image} alt="Собор во имя Николая Чудотворца" title="Храм9" />
                </div>

            </div>
            <div class="church-10">
                <div class="church-10-title">
                    <p>Церковь во имя Пророка Ильи.</p>
                </div>
            </div>
            <div class="church-11">
                <div class="church-11-title">
                    <p>Церковь в честь Воздвижения Креста Господня.</p>
                </div>
            </div>
            <div class="church-12">
                <div class="church-12-title">
                    <p>Храм Успения Пресвятой Богородицы.</p>
                </div>
            </div>
        </div>
    </section>
        </div>
    );
}

export default Iskitimskiy


*/